import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {


  public titlePage = 'Configuraciones > Cambiar contraseña';
  public userDetails = JSON.parse(this.cookie.get('ud')).userDetails;
  public MerchantActive = JSON.parse(this.cookie.get('ud')).MerchantActive;
  public merchant: any = JSON.parse(this.cookie.get('ud')).MerchantActive[0].MerchantData.MerchantCode;
  public fileName: String = 'Añadir foto de perfil';
  public srcPhoto: String = '/assets/img/photo.jfif';
  public selectedFile: File = null;
  public photoActual: String;
  info: Boolean = false;
  response: any;

  formProfile: FormGroup;
  constructor(private api: ApiService, private cookie: CookieService, private router: Router) {
    this.formProfile = this.createFormGroup();
  }

  ngOnInit() {
    console.log('iddd', this.userDetails);
    this.changeMerchant();
    this.getProfile();
  }

  changeMerchant() {
    this.api.merchant$.subscribe(m => {
      if (m.event == 'change-merchant') {
        this.merchant = m.data;
      }

    });
  }

  onSelectedFile(e) {
    this.selectedFile = e.target.files[0] as File;
    console.log(e);

  }

  onSubmit() {

    const self = this;

    if (this.password.value == this.repassword.value) {

      const data = {
        token: JSON.parse(this.cookie.get('ud')).token,
        merchant: this.merchant,
        service: 'configuration/user/change_password',
        type: 'post',
        data: {
          newpwd: this.password.value,
          userid: this.userDetails.id,
          merchantcode: this.merchant,
          metadata: JSON.stringify(JSON.parse(this.cookie.get('ud')).userDetails),
        }
      };

      this.api.api(data).toPromise().then((res: any) => {
        $('#charging').addClass('hide');
        self.response = 1;
      })
        .catch(e => {
          $('#charging').addClass('hide');
          if (e.status == 401) {
            console.log(e);
            // this.cookie.set('ud', '');
            // this.router.navigate(['/']);
          }
        });

    } else {
      self.response = 2;
    }

  }



  getProfile() {

    const data = {
      token: JSON.parse(this.cookie.get('ud')).token,
      merchant: this.merchant,
      service: 'configuration/user/' + this.merchant + '/' + this.userDetails.id,
      type: 'get-profile',
      data: ''
    };

    this.api.api(data).toPromise().then((res: any) => {
      $('#charging').addClass('hide');
      this.photoActual = res.image;

      if (res.is_active) {
        res.is_active = '1';
      }

      this.formProfile.setValue({
        fullname: res.fullname,
        mobile: res.mobile,
        profileId: res.profileid,
        email: res.email,
        status: res.is_active,
      });


    })
      .catch(e => {
        $('#charging').addClass('hide');
        if (e.status == 401) {
          this.cookie.set('ud', '');
          this.router.navigate(['/']);
        }
      });
  }

  createFormGroup() {
    return new FormGroup({
      password: new FormControl('', [Validators.required]),
      repassword: new FormControl('', [Validators.required]),
    });
  }

  toogleInfo(e) {
    if (e ==  0) {
      this.info = false;
    } else {
      this.info = true;
    }
  }


  get password() { return this.formProfile.get('password'); }
  get repassword() { return this.formProfile.get('repassword'); }

}
