import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ResponseBatchFileDetail } from '../interfaces/response-batch-file-detail';
// import { removeEmptyProperties } from 'shared-utils';
// import { environment } from '../../../../../../environments/environment';
// import { ResponseApproveBatchFile } from '../../shared/models/reponse-approve-batch-file';
// import { ResponseBatchFileDetail } from '../../shared/models/response-batch-file-detail';
// import { ResponseBatchFiles } from '../../shared/models/response-batch-files';
// import { ResponseCustomers } from '../../shared/models/response-customers';

export function removeEmptyProperties(obj: {
	[param: string]: string | number | boolean | readonly (string | number | boolean)[];
}): { [param: string]: string | number | boolean | readonly (string | number | boolean)[] } {
	return Object.entries(obj).reduce(
		(acc, [key, value]) => {
			if (value !== null && value !== undefined && value !== '' && !(Array.isArray(value) && value.length === 0)) {
				acc[key] = value;
			}
			return acc;
		},
		{} as { [param: string]: string | number | boolean | readonly (string | number | boolean)[] }
	);
}

@Injectable({
	providedIn: 'root'
})
export class CustomerService {
	private readonly API_URL = environment.api.urlClientMerchant;
	private httpClient = inject(HttpClient);

	getAllCustomers(params: {
        [param: string]: string | string[];
    }): Observable<any> {
		return this.httpClient.get<any>(`${this.API_URL}customers/list`, { params });
	}

	getBatchFiles(params: {
        [param: string]: string | string[];
    }): Observable<any> {
		const _params: any = removeEmptyProperties(params);
		return this.httpClient.get<any>(`${this.API_URL}batchfiles/list`, { params: _params });
	}

	createBatchFile(formData: FormData): Observable<any> {
		return this.httpClient.post<unknown>(`${this.API_URL}batchfiles/create`, formData);
	}

	getBatchFile(id: string): Observable<ResponseBatchFileDetail> {
		return this.httpClient.get<ResponseBatchFileDetail>(`${this.API_URL}batchfiles/detail/${id}`);
	}

	approveBatchFile(id: string | number): Observable<any> {
		return this.httpClient.put<any>(`${this.API_URL}batchfiles/approve/${id}`, {});
	}

	
}
