import { ApiService } from '../../../services/api.service';
import { FormControl } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-balance',
    templateUrl: './balance.component.html',
    styleUrls: ['./balance.component.scss']
})
export class BalanceComponent implements OnInit, OnChanges {

    @Input() balanceDetails: any = {};
    @Output() changeCurrencyBalance: EventEmitter<any> = new EventEmitter();
    @Input() currencyCodes: any[] = [];
    currencyBalanceInput: FormControl;
    isLoading = false;

    constructor(private api: ApiService, public translate: TranslateService) {
    }

    ngOnInit() {
        this.currencyBalanceInput = new FormControl(this.currencyCodes[0].CurrencyCode);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.balanceDetails && changes.balanceDetails.currentValue.currencyCode && this.currencyBalanceInput) {

            this.currencyBalanceInput.setValue(changes.balanceDetails.currentValue.currencyCode, {
                onlySelf: true,
                emitEvent: false,
                emitViewToModelChange: false
            });
            this.isLoading = false;
        }
    }

    trackByItems(index: number, item: any): number {
        return item.id;
    }

    formatDecimals(value: number) {
        return Math.floor(value * 100) / 100;
    }

    selectCurrencyBalance(value) {
        this.isLoading = true;
        this.currencyBalanceInput.setValue(value, {
            emitEvent: false,
            emitModelToViewChange: false,
            onlySelf: true,
        });
        this.changeCurrencyBalance.emit(value);
    }

}
