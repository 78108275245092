import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { NzModalService } from 'ng-zorro-antd/modal';
import { CookieService } from 'ngx-cookie-service';
import { Amortization, ResponseAmortizationConsulIt } from '../../../interfaces/IResponseAmortizationConsult';
import { ApiService } from '../../../services/api.service';
import { Model2Component } from '../../partials/tables/model2/model2.component';

@Component({
  selector: 'app-advances',
  templateUrl: './advances.component.html',
  styleUrls: ['./advances.component.scss'],
})
export class AdvancesComponent implements OnInit {
  protected filters: any = this.getFilters();
  public allCashinOrdersData: any = Promise.resolve([]);
  @ViewChild( Model2Component, {static: false} ) model2Component: Model2Component;
  protected pageSize = 10;
  protected isLoadingTable = false;
  protected dataInfo: any;
  protected currentFilters: any;
  protected merchant: any = JSON.parse(this.cookie.get('ud')).MerchantActive[0].MerchantData.MerchantCode;

  constructor(private api: ApiService, private cookie: CookieService, private nzModalService: NzModalService) { }

  ngOnInit() {
    this.changeMerchant();
  }

  changeDataTable(data) {
    this.currentFilters = data;
    
    this.allCashinOrdersData = this.allCashinOrders(
      this.cookie.get('merchant'), data);
  }

  changeMerchant() {
    this.api.merchant$.subscribe(m => {      
      if (m.event === 'change-merchant') {
        this.allCashinOrdersData = this.allCashinOrders(m.data);
        this.merchant = m.data;
        this.api.validateMerchantPermissions();
      }
    });
  }

  async getFilters() {
    return [
      {
        name: 'Seleccionar mes',
        key: 'month',
        type: 'month',
        value: moment().toDate(),
      },
      {
        name: 'Filtrar',
        key: 'button',
        type: 'button'
      },
    ]
  }

  async allCashinOrders(merchant, dataInfo: any = false) {
    this.dataInfo = dataInfo;
    const options: any = {
      params: {
        page: dataInfo.page || 1,
        monthSettlement: !!dataInfo.month ? moment(dataInfo.month).month() + 1 : moment().month() + 1,
        yearSettlement: !!dataInfo.month ? moment(dataInfo.month).year() : moment().year(),
        merchantCode: merchant,
        per_page: this.pageSize
      },
      type: 'merchant'
    }

    const headers = [
      {
        name: 'ID',
        key: 'transaction-PublicId',
        opc: true
      },
      {
        name: 'Nº de cuota',
        key: 'Installment',
        opc: true
      },
      {
        name: 'Monto',
        key: 'Amount',
        opc: true
      },
      {
        name: 'Fecha liquidación',
        key: 'DateSettlement',
        opc: true
      },
      {
        name: 'Monto anticipo',
        key: 'SubTotalAdvance',
        opc: true
      },
      {
        name: 'Liquidado',
        key: 'IsSettlement',
        type: 'status',
        options: [
          {
            true: '#108ee9', // Si
            false: '#f50', // No
          },
          {
            true: 'Si',
            false: 'No',
          }
        ],
        opc: true
      },
      {
        name: 'Estado anticipo',
        key: 'advances-Status',
        type: 'status',
        options: [
          {
            1: 'orange', // Pendiente
            2: 'green', // Aprobado
            3: 'red', // Rechazado
          },
          {
            1: 'Pendiente',
            2: 'Aprobado',
            3: 'Rechazado',
          }
        ],
        opc: true
      },
    ];

    this.isLoadingTable = true;
    const result = await this.api.queryGet<ResponseAmortizationConsulIt>('amortization/consult', options).toPromise();
    this.isLoadingTable = false;
    

    return {
      headers,
      data: {...result.data, data: this.addIdToDataItems(result.data.data)},
      source: 'cashin-payments-advances'
    };

  }

  addIdToDataItems(data) {
    
    data.forEach(item => {
      item.id = item.AmortizationID;
      item.disabled = !item.AllowAdvance || ( !item.advances ? false : item.advances.Status !== null);     
      // item.disabled = (item.Installment === 1 ? false : !item.IsSettlement ) || ( !item.advances ? false : item.advances.Status !== null);     
    });
    
    return data;
  }

  processTransaction(data: number[]) {
    this.api.queryPost('amortization/advance/create', { amortizationIds: data, status: 1 })
      .subscribe(res => {
        this.allCashinOrdersData = this.allCashinOrders(this.cookie.get('merchant'), this.dataInfo);
        this.model2Component.mapOfCheckedId = {};
        this.nzModalService.warning({
          nzTitle: 'Liquidación de cuotas',
          nzContent: 'Las cuotas han sido liquidadas correctamente'
        });
      }, err => {
        this.nzModalService.error({
          nzTitle: 'Error',
          nzContent: 'Ha ocurrido un error al liquidar las cuotas'
        });
      });
  }

  changePage(page) {
    this.allCashinOrdersData = this.allCashinOrders(
      this.cookie.get('merchant'), {...this.currentFilters, page}
  );
  }

  changePageSize(size) {
    this.pageSize = size;
  }

  createAmortization() {
    this.model2Component.operateData()
  }

  selectedItems(data: Amortization[] = []) {
    const amortizationIds = data.map(item => item.AmortizationID);

    this.nzModalService.confirm({
      nzTitle: '¿Está seguro de liquidar las cuotas seleccionadas?',
      nzContent: 'Se liquidarán las cuotas seleccionadas',
      nzOkText: 'Si',
      nzOkType: 'primary',
      nzOnOk: () => this.processTransaction(amortizationIds),
      nzCancelText: 'No',
      nzOnCancel: () => console.log('Cancel')
    });
  }

}
