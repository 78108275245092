import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-detail1',
  templateUrl: './detail1.component.html',
  styleUrls: ['./detail1.component.scss']
})
export class Detail1Component implements OnInit {

  @Input() data: any;
  @Output() dataOutput = new EventEmitter<any>();

  headers: any;
  payer: any[] = [];
  dataDetails: any;
  pathname: any = window.location.pathname;
  labelCopy = 'Copiar al portapapeles';
  constructor(private cookie: CookieService, private router: Router, private api: ApiService) { }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.data.then((result: any) => {
      $('#charging').addClass('hide');

      this.headers = result.headers;
      if (result.payer) {
        this.payer = result.payer;
      }
      result.data.then((resp: any) => {
        this.dataDetails = resp;
      });
    }).catch(error => {
      if (error.status === 401) {
        console.log('ERRT', error);
      }
    });
  }

  getDataKeys(e, h) {
    let data;
    if (e) {
      if (h.key.indexOf('/') > -1) {
        const keys = h.key.split('/');
        data = e[keys[0].split('-')[0]][keys[0].split('-')[1]] + ' ' + e[keys[1].split('-')[0]][keys[1].split('-')[1]];
      } else {
        data = e[h.key.split('-')[0]][h.key.split('-')[1]];
      }
      if (h.key.indexOf('LastStatus') > -1 && this.pathname.indexOf('cashout-operations') > -1) {
        return this.api.getStatusByIDCashoutOperations(data.toString());
      } else if (h.key.indexOf('Status') > -1 && this.pathname.indexOf('cashin-reports-sttelement') > -1) {
        return this.api.getStatusByIDCashInSttelement(data.toString());
      } else {
        return data;
      }

    }
  }


  getStatusCashinPayments(id) {
    return this.api.getStatusByID(id);
  }

  getStatusCashOutOperations(id) {
    return this.api.getStatusByIDCashoutOperations(id);
  }

  getStatusCashOutSttelement(id) {
    return this.api.getStatusByIDCashInSttelement(id);
  }

  async copyToClipboard(text) {
    try {
      await navigator.clipboard.writeText(text);
      this.labelCopy = 'Copiado';
      console.log('Content copied to clipboard:' + text);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }

    setTimeout(() => {
      this.labelCopy = 'Copiar al portapapeles';
    }, 3000);
  }
}
