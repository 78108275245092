import { ChangeDetectionStrategy, Component, Directive, ElementRef, HostListener, Input, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';


@Directive({
  selector: '[appOnlyNumber]'
})
export class OnlyNumberDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event) {
    const initialValue = this.el.nativeElement.value;

    // Remueve cualquier caracter que no sea un número del 0 al 9
    this.el.nativeElement.value = initialValue.replace(/[^0-9]/g, '');
    if (initialValue !== this.el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}

@Component({
	selector: 'app-otp-input',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
    <div class="flex-container" [ngClass]="class">
    <ng-container *ngFor="let digit of digits; let i = index">
      <input
        appOnlyNumber
        type="tel"
        class="input-field"
        [formControl]="digit"
        maxlength="1"
        (keydown)="onKeyDown(i, $event)"
        (paste)="onPaste($event)"
        (keydown.backspace)="onBackspace(i, $event)"
        (blur)="onBlur()"
        />
        <span class="separator" *ngIf="!!separateEvery && (i + 1) % separateEvery === 0 && i !== digits.length - 1"></span>
      </ng-container>
    </div>
  `,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => OtpInputComponent),
			multi: true
		}
	],
	styles: [`
		.flex-container {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	
		.input-field {
				width: 40px;
				height: 40px;
				margin-right: 0.5rem;
				text-align: center;
				border: 1px solid #D1D5DB;
				border-radius: 0.375rem;
				outline: 0;
				color: #000000D9;
		}
	
		.input-field:focus {
				border-color: #3B82F6;
		}

		.separator {
			width: 16px;
			height: 2px;
			border-radius: 0.375rem;
			margin-right: 0.5rem;
			background-color: #D1D5DB;
		}
	`]
})
export class OtpInputComponent implements OnInit, ControlValueAccessor {
	@Input() otpLength = 4;
	@Input() separateEvery = 0;
	@Input() class = '';
	digits: FormControl[] = [];

	constructor(private elementRef: ElementRef) {}

	ngOnInit(): void {
		for (let i = 0; i < this.otpLength; i++) {
			const control = new FormControl('');
			this.digits.push(control);
			control.valueChanges.subscribe(() => {
				this.updateValue();
			});
		}
	}

	onKeyDown(index: number, event: KeyboardEvent): void {
		const inputElement = event.target as HTMLInputElement;
		if (inputElement.value.length > 0 && index < this.otpLength - 1) {
			this.focusInput(index + 1);
		}
	}
	private onChange: (value: string) => void = () => {};
	private onTouched: () => void = () => {};

	writeValue(value: string): void {
		if (value && value.length === this.otpLength) {
			for (let i = 0; i < this.otpLength; i++) {
				this.digits[i].setValue(value[i], { emitEvent: false });
			}
		}
	}

	registerOnChange(fn: (value: string) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		for (const digit of this.digits) {
			isDisabled ? digit.disable({ emitEvent: false }) : digit.enable({ emitEvent: false });
		}
	}

	private focusInput(index: number): void {
		const inputs = this.elementRef.nativeElement.querySelectorAll('input');
		inputs[index].focus();
	}

	private updateValue(): void {
		let otp = '';
		for (const digit of this.digits) {
			otp += digit.value;
		}
		this.onChange(otp);
	}

	onPaste(event: ClipboardEvent): void {
		const clipboardData = event.clipboardData;
		const pastedText = clipboardData ? clipboardData.getData('text') : null
		if (pastedText) {
			pastedText.split('').forEach((char, index) => {
				const isNumeric = /^\d$/.test(char);
				if (isNumeric && index < this.otpLength) {
					this.digits[index].setValue(char);
				}
			});
		}
	}

	onBackspace(index: number, event: any): void {
		const inputElement = event.target as HTMLInputElement;
		if (inputElement.value.length === 0 && index > 0) {
			this.focusInput(index - 1);
			this.digits[index - 1].setValue('');
			this.updateValue();
		}
	}

	onBlur(): void {
		if (this.digits.every((d) => d.touched)) {
			this.onTouched();
		}
	}
}
