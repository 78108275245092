import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';
import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';



@Component({
  selector: 'app-user-maintenance',
  templateUrl: './user-maintenance.component.html',
  styleUrls: ['./user-maintenance.component.scss']
})

export class UserMaintenanceComponent implements OnInit {



  public allCashinOrdersData: any = this.allCashinOrders(this.cookie.get('merchant'));
  public allCashinOrdersDataTmp: any = this.allCashinOrders(this.cookie.get('merchant'));
  public filters: any = this.getFilters();
  public titlePage = 'Configuración > Administración de usuarios';
  public userDetails = JSON.parse(this.cookie.get('ud')).userDetails;
  public MerchantActive = JSON.parse(this.cookie.get('ud')).MerchantActive;
  public merchant: any = JSON.parse(this.cookie.get('ud')).MerchantActive[0].MerchantData.MerchantCode;
  okText: String = 'Guardar';


  isVisible = false;

  formProfile: FormGroup;

  constructor(private api: ApiService, private cookie: CookieService, private router: Router, private modalService: NzModalService) {
    this.formProfile = this.createFormGroup();
  }

  ngOnInit() {

    this.changeMerchant();


  }

  changeMerchant() {
    this.api.merchant$.subscribe(m => {
      if (m.event == 'change-merchant') {
        this.allCashinOrdersData = this.allCashinOrders(m.data);
        this.allCashinOrdersDataTmp = this.allCashinOrders(m.data);
        this.merchant = m.data;
      }
    });
  }

  async allCashinOrders(merchant, dataInfo: any = false) {

    const data = {
      token: JSON.parse(this.cookie.get('ud')).token,
      merchant,
      service: 'configuration/user/',
      type: 'get-users',
      data: []
    };

    const headers = [
      {
        name: 'ID',
        key: 'id',
        opc: true
      },
      {
        name: 'Nombre',
        key: 'fullname',
        opc: true
      },
      {
        name: 'Correo',
        key: 'email',
        opc: true
      },
      {
        name: 'Tipo de perfil',
        key: 'profileid',
        opc: true
      },
      {
        name: 'Estado',
        key: 'is_active',
        opc: true
      },
      {
        name: 'Creación',
        key: 'created_at',
        opc: true
      },
      // {
      //   'name': 'Opciones',
      //   'key': 'opc',
      //   'opc': false,
      //   'functions': ['Ver detalle']
      // },
    ];

    if (dataInfo !== false) {
      data.service = 'configuration/user/';
      data.type = 'get-users-with-params',
      data.data = dataInfo;
    }

    return {
      headers,
      data: await this.api.api(data).toPromise(),
      source: 'configuration-user-maintenance'
    };

  }



  async getFilters() {

    return [
      {
        name: 'Agregar Usuario',
        key: 'add-user',
        type: 'button'
      },
      {
        name: 'Estado',
        key: 'status',
        type: 'select',
        data: this.api.getStatusActive()
      }

    ];

  }

  changeDataTable(data) {

    if (data.type && data.type === 'search') {
      this.allCashinOrdersData = [];
      this.search(this.allCashinOrdersDataTmp, data.data);
    } else if (data == 'add-user') {
      this.showModal();
    } else {
      this.allCashinOrdersData = this.allCashinOrders(this.cookie.get('merchant'), data);
      this.allCashinOrdersDataTmp = this.allCashinOrders(this.cookie.get('merchant'), data);
    }

  }

  search(array, word) {

    word = word.toLowerCase();

    const res = [];
    let headers = [];

    if (array.headers) {

      headers = array.headers;
      let index = 0;
      let itemsProcessed = 0;
      array.data.forEach(element => {

        for (const k in element) {
          if (element.hasOwnProperty(k)) {
            if (element[k].toString().toLowerCase().indexOf(word) > -1) {
              res.push(array.data[index]);
            } else {
              if (element[k] && typeof (element[k]) == 'object') {
                for (const x in element[k]) {
                  for (const i in element[k][x]) {
                    if (element[k][x][i] && element[k][x][i].toString().toLowerCase().indexOf(word) > -1) {
                      res.push(array.data[index]);
                    }
                  }
                }
              }
            }
          }
        }

        itemsProcessed++;
        if (itemsProcessed == array.data.length) {
          this.allCashinOrdersData = {
            data: res,
            headers,
            source: 'cashin-payments-orders'
          };
        }

        index++;
      });

    } else {

      array.then((e: any) => {

        headers = e.headers;
        let index = 0;
        let itemsProcessed = 0;
        e.data.forEach(element => {
          for (const k in element) {
            if (element.hasOwnProperty(k)) {


              if (element[k] && element[k].toString().toLowerCase().indexOf(word) > -1) {
                res.push(e.data[index]);
              } else {
                if (element[k] && typeof (element[k]) == 'object') {
                  for (const x in element[k]) {
                    for (const i in element[k][x]) {
                      if (element[k][x][i] && element[k][x][i].toString().toLowerCase().indexOf(word) > -1) {
                        res.push(e.data[index]);
                      }
                    }
                  }
                }
              }
            }
          }

          itemsProcessed++;
          if (itemsProcessed == e.data.length) {
            this.allCashinOrdersData = {
              data: res,
              headers,
              source: 'cashin-payments-orders'
            };
          }

          index++;
        });
      });
    }

  }

  export() {

    const data = {
      token: JSON.parse(this.cookie.get('ud')).token,
      merchant: this.merchant,
      service: 'settlement/download/',
      data: {
        from_date: $('#f_start').val(),
        to_date: $('#f_end').val(),
      }
    };

    this.api.api(data);

  }

  showModal(): void {
    this.isVisible = true;
  }

  handleOk(): void {
    const listMerchant = [
      { MerchantCode: '1001' },
      { MerchantCode: '1002' },
      { MerchantCode: '1003' },
      { MerchantCode: '1004' }
    ];

    let fd;

    fd = {
      email: this.email.value,
      fullname: this.fullname.value,
      mobile: this.mobile.value,
      password: this.password.value,
      listMerchant,
      profileId: this.profileId.value,
      status: this.status.value,
      metadata: JSON.stringify(JSON.parse(this.cookie.get('ud')).userDetails),
    };


    const data = {
      token: JSON.parse(this.cookie.get('ud')).token,
      service: 'configuration/user',
      data: fd,
      type: 'post'
    };

    this.api.api(data).toPromise().then((res: any) => {
      $('#charging').addClass('hide');

      this.isVisible = false;

      if (res.status == true) {
          this.success();
      } else {
        if (res.message == 'Duplicate email') {
          this.error('El correo ya existe');
        } else {
          this.error('Error al crear usuario');
        }
      }

    })
      .catch(e => {
        $('#charging').addClass('hide');

        if (e.error.status == true) {
          this.success();
          this.isVisible = false;
        } else if (e.status == 401) {

          this.error('Error al crear usuario');
          console.log(e);
          // this.cookie.set('ud', '');
          // this.router.navigate(['/']);
        }
      });



  }

  handleCancel(): void {
    this.isVisible = false;
  }

  success(): void {
    this.modalService.success({
      nzTitle: 'Información',
      nzContent: 'Usuario Creado'
    });
  }

  error(e): void {
    this.modalService.error({
      nzTitle: 'Información',
      nzContent: e
    });
  }


  createFormGroup() {
    return new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      fullname: new FormControl('', [Validators.required]),
      mobile: new FormControl('', [Validators.required]),
      profileId: new FormControl('', [Validators.required]),
      status: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    });
  }


  get email() { return this.formProfile.get('email'); }
  get fullname() { return this.formProfile.get('fullname'); }
  get mobile() { return this.formProfile.get('mobile'); }
  get profileId() { return this.formProfile.get('profileId'); }
  get status() { return this.formProfile.get('status'); }
  get password() { return this.formProfile.get('password'); }



}

