import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { ApiPagination } from '../../../../interfaces/IPagination';

export interface TableColumn {
  key: string;
  title: string;
  type: 'text' | 'date' | 'template' ;
  format?: string; // para columnas de tipo fecha
  templateRef?: TemplateRef<any>; // para columnas de tipo template
  classes?: string;
}

@Component({
  selector: 'app-custom-table',
  templateUrl: './custom-table.component.html',
  styleUrls: ['./custom-table.component.scss']
})
export class CustomTableComponent {
  @Input() columns: TableColumn[] = [];
  @Input() data: any[] = [];
  @Input() showPagination = false;
  @Input() isLoading = false;
  @Input() size: 'middle' | 'small' | 'default' = 'default';
  @Input() pagination: ApiPagination = {
    currentPage: 1,
    total: 0,
    pageSize: 10,
    lastPage: 1
  }

  @Output() pageChange = new EventEmitter<number>();

  onPageChange(page: number): void {
    this.pageChange.emit(page);
  }
  

}
