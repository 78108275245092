import { AuthService } from '../../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import * as $ from 'jquery';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  response = '';
  public isLogged = false;
  formLogin: FormGroup;
  validateCaptcha = false;
  lang: any = 'es';
  isLoading = false;
  countries: any = [
    {
      name: 'Colombia',
      countryCode: 'COL'
    },
    {
      name: 'Peru',
      countryCode: 'PER'
    },
    {
      name: 'Mexico',
      countryCode: 'MEX'
    },
    {
      name: 'Brasil',
      countryCode: 'BRA'
    }
  ];

  constructor(private router: Router, private cookie: CookieService, private authService: AuthService, public translate: TranslateService) {
    this.formLogin = this.createFormGroup();
  }

  createFormGroup() {
    return new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      legal_number: new FormControl('', [Validators.required]),
      legal_name: new FormControl('', [Validators.required]),
      telephone: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required, Validators.minLength(5)]),
      acceptTerms: new FormControl(false, [Validators.requiredTrue]),
      countryCode: new FormControl('', [Validators.required]),
    });
  }


  onRegister(): void {

    if (this.formLogin.status === 'INVALID') {
      this.formLogin.markAllAsTouched();
      this.response = 'Ingrese todos los campos del formulario';
    } else if (this.validateCaptcha) {

      const data = {
        email: this.formLogin.get('email').value,
        legal_number: this.formLogin.get('legal_number').value,
        legal_name: this.formLogin.get('legal_name').value,
        phone: this.formLogin.get('telephone').value,
        description: this.formLogin.get('description').value,
        country: this.formLogin.get('countryCode').value,
        service: 'register'
      };
      this.isLoading = true;
      this.authService.api(data).subscribe((result: any) => {
        console.log('Result Resgistration', result);
        this.isLoading = false;
        if (result.success) {
          // this.cookie.set('ud', JSON.stringify(result));
          this.router.navigate(['/register-confirmation']);
        } else {
          this.response = '';
          if (result.message.legal_number) {
            this.response += result.message.legal_number + ' , ';
          }
          if (result.message.phone) {
            this.response += result.message.phone + ' , ';
          }
          if (result.message.description) {
            this.response += result.message.description + ' , ';
          }


        }
      },
        error => {
          this.isLoading = false;
          console.log(error);
          if (error.status === 401) {
            this.cookie.set('ud', '');
            this.router.navigate(['/login']);
          }
        });
    } else {
      this.response = 'Valide el recaptcha por favor';
    }

  }


  ngOnInit() {
    this.verifySession();
  }

  goLogin() {
    this.router.navigate(['/login']);
  }

  verifySession() {
    if (this.cookie.get('ud') != '') {
      this.isLogged = true;
      this.router.navigate(['/dashboard']);
    }
  }

  onResetForm() {
    this.formLogin.reset();
  }

  seePassword(e) {
    if ($('#password').attr('type') === 'password') {
      $('#password').attr('type', 'text');
    } else {
      $('#password').attr('type', 'password');
    }
  }


  resolved(captchaResponse: string) {
    if (captchaResponse) {
      this.validateCaptcha = true;
    }
  }

  changeLang(language) {
    this.lang = language;
    this.translate.use(language);
  }

  get email() { return this.formLogin.get('email'); }
  get legal_number() { return this.formLogin.get('legal_number'); }
  get legal_name() { return this.formLogin.get('legal_name'); }
  get telephone() { return this.formLogin.get('telephone'); }
  get description() { return this.formLogin.get('description'); }
  get acceptTerms() { return this.formLogin.get('acceptTerms'); }
  get countryCode() { return this.formLogin.get('countryCode'); }


}
