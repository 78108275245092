import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import * as $ from 'jquery';
import * as moment from 'moment';

@Component({
  selector: 'app-conciliation',
  templateUrl: './conciliation.component.html',
  styleUrls: ['./conciliation.component.scss']
})
export class ConciliationComponent implements OnInit {

  isVisible = false;
  isConfirmLoading = false;
  isRequestDepositModalOpen = false;
  isUseForPaymentsModalOpen = false;
  isResponseVisible = false;
  response = '';
  lang: any = this.translate.getDefaultLang();
  conciliationData: any;
  from_date: string = moment().format('YYYY-MM-DD');
  to_date: string = moment().format('YYYY-MM-DD');



  public titlePage = 'Conciliación';
  public merchant: any = JSON.parse(this.cookie.get('ud')).MerchantActive[0].MerchantData.MerchantCode;

  constructor(private api: ApiService, private cookie: CookieService, private router: Router, public translate: TranslateService) {

  }

  ngOnInit() {
    this.translateChange();
    this.getConciliationData();
  }

  translateChange() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if (event.lang == 'en') {
        this.lang = 'en';
      } else if (event.lang == 'es') {
        this.lang = 'es';
      }
    });
  }

  showModal(): void {
    this.isVisible = true;
  }

  handleOk(): void {
    this.isConfirmLoading = true;
    setTimeout(() => {
      this.isVisible = false;
      this.isConfirmLoading = false;
    }, 3000);
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  showRequestedDepositModal() {
    this.isRequestDepositModalOpen = !this.isRequestDepositModalOpen;
  }

  showUseForPaymentsModal() {
    this.isUseForPaymentsModalOpen = !this.isUseForPaymentsModalOpen;
  }

  handleRequestDepositCancel() {
    this.isRequestDepositModalOpen = false;
  }

  handleUseForPaymentsCancel() {
    this.isUseForPaymentsModalOpen = false;
  }

  handleRequestDepositOk() {
    this.processPayment('1');
    this.isRequestDepositModalOpen = false;
  }

  handlUseForPaymentseOk() {
    this.processPayment('2');
    this.isUseForPaymentsModalOpen = false;
  }

  getConciliationData() {
    const data = {
      token: JSON.parse(this.cookie.get('ud')).token,
      merchant: this.merchant,
      service: `conciliation/`,
      data: {
        from_date: this.from_date,
        to_date: this.to_date
      }
    };

    this.api.api(data).subscribe(res => {
      this.conciliationData = res;
      console.log('GET OCNCILIATION', res);
    }, error => {
      console.log('GET OCNCILIATION ERROR', error);
    });

  }



  processPayment(type: string) {
    const data = {
      token: JSON.parse(this.cookie.get('ud')).token,
      merchant: this.merchant,
      service: 'conciliation/requestmoney',
      data: {
        merchant_code: this.merchant,
        from_request: '10/10/2019',
        type,
        language: this.lang,
        metadata: JSON.stringify(JSON.parse(this.cookie.get('ud')).userDetails),
      }
    };

    this.api.api(data).toPromise().then((res: any) => {
      this.response = res.message;
      const that = this;
      setTimeout(function() {
        that.isResponseVisible = false;
      }, 30000);
      this.isResponseVisible = true;
      $('#charging').addClass('hide');
    }).catch(e => {
        this.isResponseVisible = true;
        $('#charging').addClass('hide');
        if (e.status === 401 || e.status === 0) {
          this.cookie.set('ud', '');
          this.router.navigate(['/']);
        }
    });
  }

}
