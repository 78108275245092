export enum BadgeColor {
	Red = 'red',
	Yellow = 'orange',
	Green = 'green',
	Blue = 'blue',
	Purple = 'purple',
	Magenta = 'magenta',
	Gray = 'default',
	Geekblue = 'geekblue',
  Cyan = 'cyan',
  Volcano = 'volcano',
  
}