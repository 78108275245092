import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NzModalService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { ApiPagination } from '../../../interfaces/IPagination';
import { ApiService } from '../../../services/api.service';
import { CustomerService } from '../../../services/customers.service';
import { TableColumn } from '../../partials/tables/custom-table/custom-table.component';
import { STATUS_BATCH_FILES, StatusBatchFiles } from './status-batch-files';

@Component({
  selector: 'app-batch-files',
  templateUrl: './batch-files.component.html',
  styleUrls: ['./batch-files.component.scss']
})
export class BatchFilesComponent implements OnInit {
  @ViewChild('statusNameTemplate', { static: true }) statusNameTemplate: TemplateRef<any>;
  @ViewChild('optionsTemplate', { static: true }) optionsTemplate: TemplateRef<any>;
  public filters: any = this.getFilters();
  protected isLoadingTable = false;
  protected columns: TableColumn[] = [];
  protected data: any[] = [];
  protected pagination: ApiPagination = {
    currentPage: 1,
    total: 0,
    pageSize: 10,
    lastPage: 1
  }
  protected paramsBatchFiles: any = {
    fromDate: moment().startOf('month').format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
    status: '',
    publicId: '',
    page: 1
  }

  constructor( 
    private api: ApiService,
    private cookie: CookieService, 
    private customerService: CustomerService, 
    private modalService: NzModalService,
    private router: Router
  ) {
    this.changeMerchant();
  }

  ngOnInit() {
    this.columns = [
      {
        key: 'batchFileId',
        title: 'ID',
        type: 'text'
      },
      {
        key: 'totalCreate',
        title: 'Total creadas',
        type: 'text'
      },
      {
        key: 'totalError',
        title: 'Total errores',
        type: 'text'
      },
      {
        key: 'totalRecords',
        title: 'Total registros',
        type: 'text'
      },
      {
        key: 'totalAmount',
        title: 'Monto total',
        type: 'text'
      },
      {
        key: 'lastStatus',
        title: 'Estado',
        type: 'template',
        templateRef: this.statusNameTemplate
      },
      {
        key: 'createdAt',
        title: 'Fecha de creación',
        type: 'date',
        format: 'dd/MM/yyyy HH:mm',
        classes: 'nowrap'
      },
      {
        key: 'opc',
        title: 'Opciones',
        type: 'template',
        templateRef: this.optionsTemplate
      }
    ];
  }

  changeMerchant() {
    this.api.merchant$.subscribe(m => {
      if (m.event === 'change-merchant') {
        this.api.validateMerchantPermissions();
        this.getBatchFiles();
      }
    });
  }



  async getFilters() {
    return [
      {
        name: 'Periodo',
        key: 'period',
        type: 'period',
        value: [moment().startOf('month').toDate(), moment().toDate()],
      },
      {
        name: 'Estado',
        key: 'status',
        type: 'select',
        data: [
          {
            name: STATUS_BATCH_FILES[StatusBatchFiles.PENDING_PROCESSING],
            value: StatusBatchFiles.PENDING_PROCESSING,
          },
          {
            name: STATUS_BATCH_FILES[StatusBatchFiles.PROCESS_SUCCESSFUL],
            value: StatusBatchFiles.PROCESS_SUCCESSFUL,
          },
          {
            name: STATUS_BATCH_FILES[StatusBatchFiles.PROCESS_FAILED],
            value: StatusBatchFiles.PROCESS_FAILED,
          },
          {
            name: STATUS_BATCH_FILES[StatusBatchFiles.PROCESS_WITH_ERRORS],
            value: StatusBatchFiles.PROCESS_WITH_ERRORS,
          },
          {
            name: STATUS_BATCH_FILES[StatusBatchFiles.PENDING_APPROVAL],
            value: StatusBatchFiles.PENDING_APPROVAL,
          },
          {
            name: STATUS_BATCH_FILES[StatusBatchFiles.APPROVED],
            value: StatusBatchFiles.APPROVED,
          }
        ]
      },
      {
        name: 'Buscar',
        key: 'button',
        type: 'button'
      },

    ];

  }

  changeDataTable(dataInfo) {
    const {period, button, status} = dataInfo;
    
    this.paramsBatchFiles.fromDate = moment(period[0]).format('YYYY-MM-DD');
    this.paramsBatchFiles.toDate = moment(period[1]).format('YYYY-MM-DD');
    this.paramsBatchFiles.status = status;
    this.pagination.currentPage = 1;

    this.getBatchFiles();
    
  }

  pageChange(page: number) {
    this.pagination.currentPage = page;
    this.getBatchFiles();
  }

  getBatchFiles() {
    this.isLoadingTable = true;
    const params = {
      merchantCode: this.cookie.get('merchant'),
      fromDate: this.paramsBatchFiles.fromDate,
      toDate: this.paramsBatchFiles.toDate,
      lastStatus: this.paramsBatchFiles.status,
      page: this.pagination.currentPage.toString(),
      publicId: this.paramsBatchFiles.publicId
    }
    this.customerService.getBatchFiles(params).subscribe((res: any) => {
      this.data = res.data;
      this.pagination = res.metadata;
      this.isLoadingTable = false;  
    }, (err) => {
      this.isLoadingTable = false;
    });
  }

  onApproveBatchFile(batchFileId: number, isDisabled: boolean) {
    if (isDisabled) return;
    this.modalService.confirm({
      nzTitle: '¿Estás seguro de aprobar este archivo?',
      nzContent: 'Una vez aprobado, no podrás deshacer esta acción',
      nzOnOk: () => this.approveBatchFile(batchFileId )
    });
  }

  approveBatchFile(batchFileId: number) {
    this.customerService.approveBatchFile(batchFileId).subscribe((res: any) => {
      this.modalService.success({
        nzTitle: 'Archivo aprobado',
        nzContent: 'El archivo ha sido aprobado correctamente'
      });
      this.getBatchFiles();
    }, (err) => {
      this.modalService.error({
        nzTitle: 'Error al aprobar archivo',
        nzContent: 'Ha ocurrido un error al aprobar el archivo'
      });
    });
  }

  onViewDetail(batchFileId: number) {
    this.router.navigate(['/batch-files/details', batchFileId]);
  }

}
