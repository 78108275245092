import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';
import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';


@Component({
  selector: 'app-bank-acounts',
  templateUrl: './bank-acounts.component.html',
  styleUrls: ['./bank-acounts.component.scss']
})
export class BankAcountsComponent implements OnInit {



  public allCashinOrdersData: any = this.allCashinOrders(this.cookie.get('merchant'));
  public allCashinOrdersDataTmp: any = this.allCashinOrders(this.cookie.get('merchant'));
  public titlePage = 'Cuentas bancarias';
  public userDetails = JSON.parse(this.cookie.get('ud')).userDetails;
  public MerchantActive = JSON.parse(this.cookie.get('ud')).MerchantActive;
  public merchant: any = JSON.parse(this.cookie.get('ud')).MerchantActive[0].MerchantData.MerchantCode;

  constructor(private api: ApiService, private cookie: CookieService, private router: Router) {

  }

  ngOnInit() {
    this.changeMerchant();
    this.api.validateMerchantPermissions();
    console.log(this.MerchantActive);
  }

  changeMerchant() {
    this.api.merchant$.subscribe(m => {
      if (m.event === 'change-merchant') {
        this.allCashinOrdersData = this.allCashinOrders(m.data);
        this.allCashinOrdersDataTmp = this.allCashinOrders(m.data);
        this.merchant = m.data;
        this.api.validateMerchantPermissions();
      }
    });
  }

  async allCashinOrders(merchant, dataInfo: any = false) {

    const data = {
      token: JSON.parse(this.cookie.get('ud')).token,
      merchant,
      service: 'bankAccount/',
      data: []
    };

    const headers = [
      {
        name: 'Tipo',
        key: 'Type',
        opc: true
      },
      {
        name: 'Banco',
        key: 'ShortName',
        opc: true
      },
      {
        name: 'Número de cuenta',
        key: 'NumberAccount',
        opc: true
      },
      {
        name: 'Moneda',
        key: 'CurrencyCode',
        opc: true
      },
      {
        name: 'Estado',
        key: 'IsActive',
        opc: true
      },

    ];

    if (dataInfo !== false) {
      data.service = 'cashin/transactions/WithParams';
      data.data = dataInfo;
    }
    return {
      headers,
      data: await this.api.api(data).toPromise(),
      source: 'bank-acounts'
    };
  }

}
