import { FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import * as moment from 'moment';
import * as $ from 'jquery';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  balance: any = '';
  currencyBalance: any = '';
  currencyBalanceInput = new FormControl('PEN');

  user: any;
  lang: any = this.translate.getDefaultLang();

  public data: any = {};
  public dataCashout: any = {};

  public userDetails = JSON.parse(this.cookie.get('ud')).userDetails;
  public MerchantActive = JSON.parse(this.cookie.get('ud')).MerchantActive;
  public merchant: any = JSON.parse(this.cookie.get('merchant'));
  public convertion: any;
  merchantCashinActive: any = true;
  merchantCashoutActive: any = true;

  currencies: any;

  single: any[];
  view: any[] = [360, 140];
  dataChart1: any = {};
  dataChart2: any[];
  dataChart4: any[];
  dataChart5: any[];

  // options
  gradient = true;
  showLegend = true;
  showLabels = true;
  isDoughnut = false;

  styleDate = {
    'min-width': '200px',
  };


  colorScheme = {
    domain: ['#ff9100', '#072146', '#28c3d4', '#4f4f4f']
  };

  from_date: any = moment().startOf('month').toDate();
  from_date_cashout: any = moment().startOf('month').toDate();
  to_date: any = moment().toDate();
  to_date_cashout: any = moment().toDate();
  currency_code = new FormControl(null);
  currency_code2 = new FormControl(null);
  isVisible = false;
  dataMerchant = [];


  // TRADUCCIONES CUSTOMIZADAS

  Generic = 'Genérico';
  Online = 'En linea';
  Cash = 'Efectivo';

  public balanceByBank: any[];

  constructor(private api: ApiService, private cookie: CookieService, private router: Router, public translate: TranslateService) {
  }

  ngOnInit() {
    this.getCurrenciesByIdMerchant(this.merchant, this.getCurrentMerchantActive());
    this.validateMerchantPermissions();
    this.changeMerchant();
    this.getData();
    this.getDataCashout();
    this.translateChange();
    this.getBalancebyBank();
  }

  getCurrentMerchantActive(data?) {
    const listMerchant = data ? data : JSON.parse(this.cookie.get('ud' || '{}')).MerchantActive || [];
    return listMerchant.find(e => e.MerchantData.MerchantCode === Number(this.merchant));
  }

  validateMerchantPermissions() {
    if (this.cookie.get('ud') !== '') {

      const merchantList = JSON.parse(this.cookie.get('ud')).MerchantActive;
      const selectedMerchantCode = JSON.parse(this.cookie.get('merchant'));
      let selectedItem;

      merchantList.forEach(item => {
        if (item.MerchantData.MerchantCode === selectedMerchantCode) {
          selectedItem = item;
        }
      });

      if (selectedItem) {
        this.merchantCashinActive = selectedItem.MerchantData.ActiveCashin;
        this.merchantCashoutActive = selectedItem.MerchantData.ActiveCashout;

        (this.merchantCashinActive) ? $('#navCashin').show() : $('#navCashin').hide();
        (this.merchantCashoutActive) ? $('#navCashout').show() : $('#navCashout').hide();
      }
    }
  }

  translateChange() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if (event.lang === 'en') {
        this.Generic = 'Generic';
        this.Online = 'Online';
        this.Cash = 'Cash';
      } else if (event.lang === 'es') {
        this.Generic = 'Genérico';
        this.Online = 'En linea';
        this.Cash = 'Efectivo';
      } else if (event.lang === 'pt') {
        this.Generic = 'Genérico';
        this.Online = 'Online';
        this.Cash = 'Cash';
      }

      this.dataChart2 = this.dataChart2.map((item) => {
        item.name = this.translate.instant(item.key);
        return item;
      });
    });
  }

  getCurrenciesByIdMerchant(id: number, data) {
    if (data.MerchantData.MerchantCode === id) {
      this.currencies = data.CurrencySupported;
      if (this.currencies.Cashin.length) {
        this.currency_code.setValue(this.currencies.Cashin[0].CurrencyCode);
      }
      if (this.currencies.Cashout.length) {
        this.currency_code2.setValue(this.currencies.Cashout[0].CurrencyCode);
      }
    }
  }
  changeMerchant() {
    this.api.merchant$.subscribe(m => {
      if (m.event === 'change-merchant') {
        this.validateMerchantPermissions();
        this.merchant = m.data;
        this.getCurrenciesByIdMerchant(Number(m.data), this.getCurrentMerchantActive(m.dataMerchant));
        this.getData();
        this.getDataCashout();
        this.getBalancebyBank();
      }
    });
  }

  private getBalancebyBank(): void {
    const data = {
      token: JSON.parse(this.cookie.get('ud')).token,
      service: 'balancebyBanck',
      data: this.merchant
    };

    $('#charging').removeClass('hide');

    this.api.api(data).toPromise().then((res: any) => {
      $('#charging').addClass('hide');
      this.balanceByBank = res.data;
    });
  }

  getData() {
    const data = {
      token: JSON.parse(this.cookie.get('ud')).token,
      merchant: this.merchant,
      service: 'dasboard/cashin/',
      data: {
        from_date: moment(new Date(this.from_date)).format('YYYY-MM-DD'),
        to_date: moment(new Date(this.to_date)).format('YYYY-MM-DD'),
        currency_code: this.currency_code.value,
      }
    };

    $('#charging').removeClass('hide');

    this.api.api(data).toPromise().then((res: any) => {

      $('#charging').addClass('hide');

      this.data = res;
      const dataRes = [];
      dataRes.push({
        name: this.translate.instant('general.online'),
        value: Number(res.graphic2.percentage_online) / 100 * Number(res.graphic2.total_amount),
        key: 'general.online'
      });
      dataRes.push({
        name: this.translate.instant('general.cash'),
        value: Number(res.graphic2.percentage_cash) / 100 * Number(res.graphic2.total_amount),
        key: 'general.cash'
      });

      this.dataChart2 = dataRes;
      this.convertion = res.graphic3.Convertion;
      this.dataChart4 = res.graphic4;
      this.dataChart5 = res.graphic5;

    })
      .catch(e => {
        $('#charging').addClass('hide');
        if (e.status === 401 || e.status === 0) {
          this.cookie.set('ud', '');
          this.router.navigate(['/']);
        }
      });
  }

  getDataCashout() {
    const data = {
      token: JSON.parse(this.cookie.get('ud')).token,
      merchant: this.merchant,
      service: 'dasboard/cashout/',
      data: {
        from_date: moment(new Date(this.from_date_cashout)).format('YYYY-MM-DD'),
        to_date: moment(new Date(this.to_date_cashout)).format('YYYY-MM-DD'),
        currency_code: this.currency_code.value,
      }
    };

    $('#charging').removeClass('hide');

    this.api.api(data).toPromise().then((res: any) => {
      $('#charging').addClass('hide');
      this.dataCashout = res;
    })
    .catch(e => {
      $('#charging').addClass('hide');
      if (e.status === 401  || e.status === 0) {
        this.cookie.set('ud', '');
        this.router.navigate(['/']);
      }
    });
  }

  getStatusColorBank(balanceAvailable, minAmount): string {
    return Number(balanceAvailable) <= Number(minAmount) ? '#ee362d' : '#4CA54B';
  }

  onSelect(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  toogleDetail(e) {
    $('#detail_' + e).toggleClass('hide');
  }

  toogleDetail2(e) {
    $('#detail2_' + e).toggleClass('hide');
  }

}
