import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { UtilitiesService } from '../../../../services/utilities.service';
import { Bank } from '../../../../interfaces/Bank';
import { ApiService } from '../../../../services/api.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-modal-move-balance-dispersion',
    templateUrl: './modal-move-balance-dispersion.component.html',
    styleUrls: ['./modal-move-balance-dispersion.component.scss']
})
export class ModalMoveBalanceDispersionComponent implements OnInit, OnChanges, OnDestroy {
    @Input() isVisible = false;
    @Input() isLoading = false;
    @Output() changeCurrencyBalance: EventEmitter<any> = new EventEmitter();
    @Output() nzOnCancel = new EventEmitter<void>();
    @Output() nzOnOk = new EventEmitter<any>();
    @Input() balanceDetails: any = {};
    public destroy$ = new Subject();
    public merchantId = this.cookie.get('merchant') ? JSON.parse(this.cookie.get('merchant')) : null;
    public merchants = this.cookie.get('ud') ? JSON.parse(this.cookie.get('ud')).MerchantActive : null;
    public banks: Bank[];
    public form: FormGroup;
    public currencyCodes: any[] = [];
    public supportedCountries: any[] = [];
    public errors: any = {
        currency: [
            {error: 'required', message: 'Currency is required'}
        ],
        amount: [
            {error: 'required', message: 'This field is required'},
            {error: 'pattern', message: 'This field must be a valid number'},
            {error: 'min', message: 'This field must be greater than 0'},
            {error: 'max', message: 'This field must be less than or equal to the balance'}
        ],
        country: [
            {error: 'required', message: 'This field is required'}
        ],
        bank: [
            {error: 'required', message: 'This field is required'}
        ]
    };

    constructor(
        private fb: FormBuilder,
        private utilitiesService: UtilitiesService,
        private cookie: CookieService,
        public api: ApiService,
    ) {
        this.createForm();
    }

    ngOnInit() {
        const merchantActive = this.merchants.find(merchant => merchant.MerchantData.MerchantCode === this.merchantId);
        this.supportedCountries = merchantActive.CountrySupport;

        this.currency.valueChanges.subscribe(value => {
            if (value) {
                this.changeCurrencyBalance.emit(value);
            }
        });
        this.country.valueChanges.subscribe(value => {
            if (value) {
                this.getBanks(value);
            }
        });
        this.currencyCodes = this.utilitiesService.getSupportedCurrencyCodes();
        this.changeMerchant();
    }

    async getBanks(countryCode: string) {
        this.utilitiesService.updateLoading(true);
        this.banks = await this.utilitiesService.getBanks({parent: 1, countryCode});
        this.utilitiesService.updateLoading(false);
        this.bank.enable();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.balanceDetails && changes.balanceDetails.currentValue.commissions) {
            this.form.get('amount').setValidators([
                Validators.required, Validators.min(1),
                Validators.max(Number(changes.balanceDetails.currentValue.commissions))
            ]);
        }
        if (changes.balanceDetails && changes.balanceDetails.currentValue.currencyCode) {
            this.currency.setValue(changes.balanceDetails.currentValue.currencyCode, {emitEvent: false});
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    changeMerchant() {
        this.api.merchant$.pipe(
            takeUntil(this.destroy$)
        ).subscribe(m => {
            if (m.event === 'change-merchant') {
                this.currencyCodes = this.utilitiesService.getSupportedCurrencyCodes();
                const merchantActive = this.merchants.find(merchant => merchant.MerchantData.MerchantCode === this.merchantId);
                this.supportedCountries = merchantActive.CountrySupport;
            }
        });
    }

    createForm() {
        this.form = this.fb.group({
            bank: [{value: null, disabled: true}, [Validators.required]],
            country: [null, [Validators.required]],
            currency: [null, [Validators.required]],
            amount: ['', [Validators.required, Validators.min(1), Validators.max(Number(this.balanceDetails.commissions))]]
        });

        this.form.controls.amount.valueChanges.subscribe(value => {
            this.updateValue(value);
        });
    }

    updateValue(value: string): void {
        const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
        if ((!isNaN(+value) && reg.test(value)) || value === '' || value === '-') {
            console.log(value);
            this.form.controls.amount.setValue(value, {emitEvent: false});
        } else {
            if (this.form.controls.amount.value) {
                this.form.controls.amount.setValue(this.form.controls.amount.value.slice(0, -1), {emitEvent: false});
            }
        }
    }

    onBlur(): void {
        if (this.form.controls.amount.value
            && this.form.controls.amount.value.charAt(this.form.controls.amount.value.length - 1) === '.'
            || this.form.controls.amount.value === '-') {
            this.updateValue(this.form.controls.amount.value.slice(0, -1));
        }
    }

    formatDecimals(value: number) {
        return Math.floor(value * 100) / 100;
    }

    onCancel() {
        this.form.reset();
        this.nzOnCancel.emit();
    }

    onOk() {
        this.nzOnOk.emit(this.form.value);
    }

    get currency() {
        return this.form.get('currency');
    }

    get country() {
        return this.form.get('country');
    }

    get bank() {
        return this.form.get('bank');
    }

}
