import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';
import { Bank } from '../interfaces/Bank';
import { MerchantActive, ResponseLogin } from '../interfaces/ResponseLogin';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class UtilitiesService {
    private loading = new BehaviorSubject(false);
    public loading$ = this.loading.asObservable();


    constructor(private api: ApiService, private router: Router, private cookie: CookieService,) {
    }

    async getBanks(params?: any): Promise<Bank[]> {
        return await this.api.queryGet('bank', {type: 'admin', params}).toPromise() as Promise<Bank[]>;
    }

    public getSupportedCurrencyCodes() {
        if (this.getMerchantActive()) {
            return this.removeDuplicates([
                ...this.getMerchantActive().CurrencySupported.Cashin,
                ...this.getMerchantActive().CurrencySupported.Cashout
            ]);
        }
        return [];
    }


    public updateLoading(value: boolean) {
        this.loading.next(value);
    }

    removeDuplicates(arr) {
        return arr.filter((obj, pos, arr) => {
            return arr.map(mapObj => mapObj.CurrencyCode).indexOf(obj.CurrencyCode) === pos;
        });
    }

    getMerchantActive(): MerchantActive | null {
        const merchantId = JSON.parse(this.cookie.get('merchant'));
        const ud: ResponseLogin = JSON.parse(this.cookie.get('ud'));
        if (ud) {
            return ud.MerchantActive.find(merchant => merchant.MerchantData.MerchantCode === merchantId);
        }
        return null;
    }
}
