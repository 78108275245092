
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment';

const API = environment.api;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  urlCore: string = API.urlMerchant;


  constructor(private http: HttpClient, private cookie: CookieService, public router: Router) {

  }


  api(datos) {

    switch (datos.service) {

      case 'auth/login':
        return this.http.post(`${this.urlCore + datos.service}`, datos);
      case 'register':
        return this.http.post(`${this.urlCore + datos.service}`, datos);

      default:

        break;

    }


  }

}
