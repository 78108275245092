import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UtilitiesService } from './services/utilities.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Wepay4U';
  loading = false;
  constructor(private translate: TranslateService, private utilsService: UtilitiesService) {
    translate.setDefaultLang('es');
  }

  ngOnInit() {
    this.utilsService.loading$.subscribe(loading => this.loading = loading);
  }

}
