export enum StatusBatchFiles {
	PENDING_PROCESSING = 1,
	PROCESS_SUCCESSFUL = 2,
	PROCESS_FAILED = 3,
	PROCESS_WITH_ERRORS = 4,
	PENDING_APPROVAL = 5,
	APPROVED = 6,
	UNKNOWN = 0
}

export const STATUS_BATCH_FILES = {
	[StatusBatchFiles.PENDING_PROCESSING]: 'Pendiente de procesamiento',
	[StatusBatchFiles.PROCESS_SUCCESSFUL]: 'Procesado exitosamente',
	[StatusBatchFiles.PROCESS_FAILED]: 'Procesamiento fallido',
	[StatusBatchFiles.PROCESS_WITH_ERRORS]: 'Procesado con errores',
	[StatusBatchFiles.PENDING_APPROVAL]: 'Pendiente de aprobación',
	[StatusBatchFiles.APPROVED]: 'Aprobado',
	[StatusBatchFiles.UNKNOWN]: 'Desconocido'
};
