import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

const API_ACCESS_URL = environment.api.urlAccess;

export interface ResponseValidateUser {
	statusCode: number;
	status: boolean;
	message: string;
	data: boolean;
	metadata: any;
}

export interface PasswordChangeRequest {
    email:           string;
    currentPassword: string;
    newPassword:     string;
    confirmPassword: string;
}

export enum Service {
	CASH_IN = 'cashin',
	CASH_OUT = 'cashout',
	BROKER = 'broker',
	RECHARGE = 'recharge'
}


@Injectable({
    providedIn: 'root'
})
export class AccessService {

    constructor(private http: HttpClient) {
    }

    
    get tokenAccess(): string {
        return localStorage.getItem('authAccess') != null ? JSON.parse(localStorage.getItem('authAccess')).data.access_token : '';
    }

    login(data: { email: string, password: string }) {
        const url = `${API_ACCESS_URL}login`;
        return this.http.post(url, {...data, service: Service.CASH_IN, console: 'CONSOLE_MERCHANT_V1'}).pipe(
            map((res: any) => {
                return res;
            }));
    }

    isUseTokenGoogle(){
        const url = `${API_ACCESS_URL}google/validate/register`;
        return this.http.get(url, {params: {service: Service.CASH_IN}}).pipe(
            map((res: any) => {
                return res;
            }));
    }

    isUseTokenWithUserIdGoogle(userId: number | string ){ 
        const url = `${API_ACCESS_URL}google/validate/register/` + userId;
        return this.http.get(url, {params: {service: Service.CASH_IN}}).pipe(
            map((res: any) => {
                return res;
            }));
    }

    getQrWithUseridGoogle(userId: number, isReset: boolean){
        const url = `${API_ACCESS_URL}google/qr/user`;
        return this.http.post(url, {userId, isReset, service: Service.CASH_IN }).pipe(
            map((res: any) => {
                return res;
            }));
    }

    getQrGoogle(isReset: boolean){
        const url = `${API_ACCESS_URL}google/qr`;
        return this.http.post(url, {isReset, service: Service.CASH_IN}).pipe(
            map((res: any) => {
                return res;
            }));
    }

    validateAuthToken(token: string, userId?: number): Observable<ResponseValidateUser> {
		  const user = sessionStorage.getItem('ud') ? JSON.parse(sessionStorage.getItem('ud')) : null;
        const id = userId ? userId : (user ? user.userDetails.id : undefined)
        return this.http
			.post<ResponseValidateUser>(
				`${API_ACCESS_URL}google/validate`,
          { userId: id, twoFactorAuthenticationToken: token, service: Service.CASH_IN },
			)
	}

    resetPassword(data: PasswordChangeRequest) {
        const url = `${API_ACCESS_URL}login/reset-password`;
        return this.http.post(url, {...data, service: Service.CASH_IN}).pipe(
            map((res: any) => {
                return res;
            }));
    }
}