import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
moment.locale('es', {
  week: {
    dow: 1,
    doy: 4
  }
});


@Component({
  selector: 'app-filter1',
  templateUrl: './filter1.component.html',
  styleUrls: ['./filter1.component.scss']
})
export class Filter1Component implements OnInit {

  @Input() data: any;
  @Input() showSearch = true;
  @Output() dataOutput = new EventEmitter<any>();

  form: FormGroup;
  items: FormArray;
  dataFields: any;
  isVisible = false;
  inputSearch: FormControl = new FormControl('');
  date = null;
  dateRange = [moment().startOf('month').toDate(), moment().toDate()];
  buttonDownload = false;
  range: any = null;
  ranges1: any = {
    Ayer: [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
    Hoy: [new Date(), new Date()],
    'Esta Semana': [moment().weekday(0).toDate(), moment().weekday(6).toDate()],
    'Mes pasado': [moment().subtract(1, 'months').startOf('month').toDate(), moment().subtract(1, 'months').endOf('month').toDate()],
    'Mes actual': [moment().startOf('month').toDate(), moment().toDate()],
    'Último trimestre': [moment().subtract(3, 'months').startOf('month').toDate(), moment().subtract(1, 'months').endOf('month').toDate()],
  };

  constructor(private fb: FormBuilder, public translate: TranslateService) {}

  ngOnInit() {
    this.searchWithControl();
    this.translateChange();
    this.data.then((data: any) => {
      this.dataFields = data;
      const item = {};
      data.forEach((e: any) => {
        if (e.key == 'download') {
          this.buttonDownload = true;
        }
        const fromDate = moment().startOf('month').format('YYYY-MM-DD');
        const toDate = moment().format('YYYY-MM-DD');

        if (Array.isArray(e.key)) {
          // item['period'] = '3';
          item[e.key[0]] = fromDate;
          item[e.key[1]] = toDate;
        } else {
          if (e.type === 'select') {
            item[e.key] = null;
          } else {
            item[e.key] = e.value ? [e.value] : '';
          }
        }

      });

      this.form = this.fb.group({
        ...item
      });
      this.onChangePeriod();

    });
  }

  translateChange() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.ranges1 = this.getRanges();
    });
  }

  getRanges() {
    return {
      [this.translate.instant('filter.yesterday')]: [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
      [this.translate.instant('filter.today')]: [new Date(), new Date()],
      [this.translate.instant('filter.lastweek')]: [moment().weekday(0).toDate(), moment().weekday(6).toDate()],
      [this.translate.instant('filter.lastmonth')]: [moment().subtract(1, 'months').startOf('month').toDate(),  moment().subtract(1, 'months').endOf('month').toDate()],
      [this.translate.instant('filter.currentMonth')]: [moment().startOf('month').toDate(), moment().toDate()],
      [this.translate.instant('filter.lasttrimester')]: [moment().subtract(3, 'months').startOf('month').toDate(), moment().subtract(1, 'months').endOf('month').toDate()],
    };
  }

  onChangePeriod() {
    this.dataOutput.emit(this.form.value);
  }

  searchWithControl() {
    this.inputSearch.valueChanges
        .pipe(debounceTime(400), distinctUntilChanged())
        .subscribe(value => {
          this.search(value);
        });
  }

  search(e) {
    this.dataOutput.emit({
      type: 'search',
      data: e,
      ...this.form.value
    });
  }

  processAditionalButton(e) {
    this.onChangePeriod();
  }

  processAditionalButtonDownload(e: any) {
    this.dataOutput.emit({
      type: e,
      data: this.getData()
    });
  }

  process() {
    this.form.get('items').reset();
    this.dataOutput.emit(this.getData());
  }

  getData() {
    const dataValues = (this.form.get('items') as FormArray).controls[0].value;
    return dataValues ? dataValues : '';
  }

  get formData() { return (this.form.get('items') as FormArray).controls; }

  handleOk(): void {
    this.isVisible = false;
  }

  handleCancel(): void {
    this.isVisible = false;
  }

}
