import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';
import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { element } from 'protractor';
import { ITotaltable } from '../../../../interfaces/ITotaltable';
import * as moment from 'moment';

@Component({
  selector: 'app-payroll',
  templateUrl: './payroll.component.html',
  styleUrls: ['./payroll.component.scss']
})
export class PayrollComponent implements OnInit {

  public allCashinOrdersData: any = this.allCashinOrders(this.cookie.get('merchant'));
  public allCashinOrdersDataTmp: any = this.allCashinOrders(this.cookie.get('merchant'));
  public filters: any = this.getFilters();
  public titlePage = 'Cash Out > Nóminas';
  public userDetails = JSON.parse(this.cookie.get('ud')).userDetails;
  public MerchantActive = JSON.parse(this.cookie.get('ud')).MerchantActive;
  public merchant: any = JSON.parse(this.cookie.get('ud')).MerchantActive[0].MerchantData.MerchantCode;
  public totaltable: ITotaltable = {
    countRow : 0,
    currency: '',
    totalCOP: 0,
    totalPEN: 0,
    TotalUSD: 0
  };
  from_date: string = moment().format('YYYY-MM-DD');
  to_date: string = moment().format('YYYY-MM-DD');

  constructor(private api: ApiService, private cookie: CookieService, private router: Router) {

  }

  ngOnInit() {
    this.changeMerchant();
    console.log(this.MerchantActive);
    this.api.validateMerchantPermissions();
  }

  changeMerchant() {
    this.api.merchant$.subscribe(m => {
      if (m.event === 'change-merchant') {
        this.allCashinOrdersData = this.allCashinOrders(m.data);
        this.allCashinOrdersDataTmp = this.allCashinOrders(m.data);
        this.merchant = m.data;
        this.api.validateMerchantPermissions();
      }
    });
  }

  async allCashinOrders(merchant, dataInfo: any = false) {

    const data = {
      token: JSON.parse(this.cookie.get('ud')).token,
      merchant,
      service: 'cashout/payroll/',
      data: {
        from_date:  moment().format('YYYY-MM-DD'),
        to_date: moment().format('YYYY-MM-DD'),
      }
    };

    const headers = [
      {
        name: 'ID',
        key: 'PublicID',
        opc: true
      },

      {
        name: 'Total registros',
        key: 'TotalRecords',
        opc: true
      },
      {
        name: 'Monto total',
        key: 'TotalAmount',
        opc: true
      },
      {
        name: 'Moneda',
        key: 'CurrencyCode',
        opc: true
      },
      {
        name: 'Fecha creación',
        key: 'created_at',
        opc: true
      },

      {
        name: 'Total Creados',
        key: 'TotalCreate',
        opc: true
      },
      {
        name: 'Total Errores',
        key: 'TotalError',
        opc: true
      },
      {
        name: 'Estado',
        key: 'LastStatus',
        opc: true
      },
      {
        name: 'Opciones',
        key: 'opc',
        opc: false,
        functions: ['Aprobar']
      },
    ];

    if (dataInfo !== false) {
      data.service = 'cashout/payroll/WithParams';
      data.data = dataInfo;
    }
    const result = await this.api.api(data).toPromise() as any[];

    this.totaltable = {
      countRow : 0,
      currency: '',
      totalCOP: 0,
      totalPEN: 0,
      TotalUSD: 0
    };
    result.forEach((item, i) => {
      let laguage = 'es-CO';
      if (item.CurrencyCode === 'PEN') {
        laguage = 'es-PE';
        this.totaltable.totalPEN += Number(String(item.TotalAmount).replace(',', '.'));
      }
      if (item.CurrencyCode === 'USD') {
        laguage = 'en-US';
        this.totaltable.TotalUSD += Number(String(item.TotalAmount).replace(',', '.'));
      }
      if (item.CurrencyCode === 'COP') {
        this.totaltable.totalCOP += Number(String(item.TotalAmount).replace(',', '.'));
      }
      const formatter = new Intl.NumberFormat(laguage, {
        style: 'decimal',
        currency: item.CurrencyCode,
        minimumFractionDigits: 2
      });
      result[i].TotalAmount = formatter.format(Number(String(item.TotalAmount).replace(',', '.')));
      // result[i].TotalAmount = formatter.format(item.TotalAmount);
    });

    this.totaltable.currency = 'COP';
    this.totaltable.countRow = result.length;

    return {
      headers,
      data: result,
      source: 'cashout-payroll'
    };

  }



  async getFilters() {

    return [
      {
        name: 'Periodo',
        key: 'period',
        type: 'period',
        value: [moment().startOf('month').toDate(), moment().toDate()]
      },
      {
        name: 'Estado',
        key: 'status',
        type: 'select',
        data: this.api.getStatusPayroll()
      },
      {
        name: 'Buscar',
        key: 'button',
        type: 'button'
      },

    ];

  }

  changeDataTable(data) {
    this.from_date = data.period ? moment(data.period[0]).format('YYYY-MM-DD') : '';
    this.to_date = data.period ? moment(data.period[1]).format('YYYY-MM-DD') : '';

    if (data.type && data.type === 'search') {
      if (data.data === '') {
        this.allCashinOrdersData = this.allCashinOrdersDataTmp;
        this.allCashinOrdersDataTmp = this.allCashinOrdersData;
      } else {
        this.allCashinOrdersData = [];
        this.search(this.allCashinOrdersDataTmp, data.data);
      }
    } else {
      this.allCashinOrdersData = this.allCashinOrders(this.cookie.get('merchant'), {...data, from_date: this.from_date, to_date: this.to_date});
      this.allCashinOrdersDataTmp = this.allCashinOrdersData;
    }
  }


  search(array, word) {

    word = word.toLowerCase();

    const res = [];
    let headers = [];

    if (array.headers) {

      headers = array.headers;
      let index = 0;
      let itemsProcessed = 0;
      array.data.forEach(element => {

        for (const k in element) {
          if (element.hasOwnProperty(k)) {
            if (element[k].toString().toLowerCase().indexOf(word) > -1) {
              res.push(array.data[index]);
            } else {
              if (element[k] && typeof (element[k]) == 'object') {
                for (const x in element[k]) {
                  for (const i in element[k][x]) {
                    if (element[k][x][i] && element[k][x][i].toString().toLowerCase().indexOf(word) > -1) {
                      res.push(array.data[index]);
                    }
                  }
                }
              }
            }
          }
        }

        itemsProcessed++;
        if (itemsProcessed == array.data.length) {
          this.allCashinOrdersData = {
            data: res,
            headers,
            source: 'cashout-payroll'
          };
        }

        index++;
      });

    } else {

      array.then((e: any) => {

        headers = e.headers;
        let index = 0;
        let itemsProcessed = 0;
        e.data.forEach(element => {
          for (const k in element) {
            if (element.hasOwnProperty(k)) {


              if (element[k] && element[k].toString().toLowerCase().indexOf(word) > -1) {
                res.push(e.data[index]);
              } else {
                if (element[k] && typeof (element[k]) == 'object') {
                  for (const x in element[k]) {
                    for (const i in element[k][x]) {
                      if (element[k][x][i] && element[k][x][i].toString().toLowerCase().indexOf(word) > -1) {
                        res.push(e.data[index]);
                      }
                    }
                  }
                }
              }
            }
          }

          itemsProcessed++;
          if (itemsProcessed == e.data.length) {
            this.allCashinOrdersData = {
              data: res,
              headers,
              source: 'cashout-payroll'
            };
          }

          index++;
        });
      });
    }

  }

  export() {

    const data = {
      token: JSON.parse(this.cookie.get('ud')).token,
      merchant: this.merchant,
      service: 'cashout/transactions/download/',
      data: {
        from_date: this.from_date,
        to_date: this.to_date,
      }
    };

    this.api.api(data);
    $('#charging').addClass('hide');
  }

}
