import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';

import { ToastrService } from 'ngx-toastr';

import * as $ from 'jquery';
import { Pagination } from '../../../../interfaces/IPagination';

@Component({
  selector: 'app-model2',
  templateUrl: './model2.component.html',
  styleUrls: ['./model2.component.scss']
})
export class Model2Component implements OnInit {


  @Input() data: any;
  @Input() isLoading = false;
  @Input() showCheckbox = false;
  @Output() dataOutput = new EventEmitter<any>();
  @Output() changePageEmit = new EventEmitter<any>();
  @Output() changePageSizeEmit = new EventEmitter<any>();
  @Output() selectedItemsEmit = new EventEmitter<any[]>();

  isAllDisplayDataChecked = false;
  isIndeterminate = false;
  listOfDisplayData: any[] = [];
  mapOfCheckedId: { [key: string]: boolean } = {};
  numberOfChecked = 0;


  infoTable: any[] = [];
  headers: any;
  source: any;
  title: any = false;
  isVisible = false;
  isVisibleModalAprobar = false;
  titleModal: any;
  titleModalAprobar: any;
  modalInfo: any;
  modalKeys: any = [];
  contentModal: any = '';
  classFunctions: any = '';
  disabled = '';
  dataPublicID: any;
  eventTarget: any;
  path: any = window.location.pathname;
  pagination: Pagination;


  keyAprobar = new FormControl('');
  public merchant: any = JSON.parse(this.cookie.get('ud')).MerchantActive[0].MerchantData.MerchantCode;
  public posHeaderAmount: number;

  // Expand
  mapOfExpandData: { [key: string]: boolean } = {

  };
  // *
   constructor(private cookie: CookieService, private router: Router, private api: ApiService, private modalService: NzModalService, private toastr: ToastrService) { }

   ngOnInit() {
     this.getInfo();
     this.api.validateMerchantPermissions();
    }
    // expand

    // *
    changeMerchant() {
    this.api.merchant$.subscribe(m => {
      if (m.event === 'change-merchant') {
        this.merchant = m.data;
        this.api.validateMerchantPermissions();
      }
    });
  }
  formantExpand(array) {    
    let newarray = [];
    let cont = 1;

    array.forEach(res => {
      newarray = [...newarray, {...res, expand: false, id: cont}];
      cont++;

    });    
    return newarray;
  }

  getInfo() {
    if (this.data.headers) {

      if (this.data.title) {
        this.title = this.data.title;
      }
      this.headers = this.data.headers;

      this.source = this.data.source;
      if (this.data.data) {
        this.infoTable = this.formantExpand(this.data.data);        
      }


    } else {

      this.data.then((data: any) => {
        $('#charging').addClass('hide');

        if (data.title) {
          this.title = data.title;
        }
        this.headers = data.headers;


        this.source = data.source;
        if (data.data) {
          // this.infoTable = this.formantExpand(data.data);
          if (Array.isArray(data.data)) {
            this.infoTable = this.formantExpand(data.data);
          } else {
            const { data: results, ...pagination} = data.data;            
            this.infoTable = this.formantExpand(results);
            this.pagination = pagination;
          }
        }

      }).catch(error => {
        if (error.status === 401) {
          this.cookie.set('ud', '');
          this.router.navigate(['/']);
        }
      });
    }

  }




  ngOnChanges() {
    this.getInfo();
  }

  getDataKeys(object: {[key: string]: any}, key: string) {     
    const properties = key.split('-');
    let value = object;
    for (const property of properties) {
      value = value[property] ? value[property] : value[property] === false ? 'false' : '';
    }
    return value.toString() ? value.toString() : '';
  }

  // INFORMATION OF REQUEST AND RESPONSE
  viewModal(e, type) {

    this.titleModal = 'Información';
    this.contentModal = '';


    if (type === false) {
      this.modalInfo = e;

      this.contentModal += `
                            <div class="d-flex">
                              <div class="col-6 font-weight-bold">Mensaje</div>
                              <div><b>:</b> ${this.modalInfo}</div>
                            </div>
                                `;


    } else {
      this.modalInfo = JSON.parse(JSON.parse(e));
      Object.keys(this.modalInfo).forEach(e => {
        switch (e) {
          case 'message':
            this.contentModal += `
                                <div class="d-flex">
                                  <div class="col-6 font-weight-bold">Mensaje</div>
                                  <div><b>:</b> ${this.modalInfo[e]}</div>
                                </div>
                                `;
            break;

          default:
            this.contentModal += `
                                <div class="d-flex">
                                  <div class="col-6 font-weight-bold">${e}</div>
                                  <div><b>:</b> ${this.modalInfo[e]}</div>
                                </div>
                                `;

            break;
        }
      });
    }




    this.showModal();


  }

  // MODAL

  success(msj): void {

    if (msj == '') {
      msj = 'Se aprobó correctamente';
    }
    const modal = this.modalService.success({
      nzTitle: 'Información',
      nzContent: msj,
      nzOkText: 'Aceptar'
    });

    setTimeout(() => modal.destroy(), 5000);
  }



  showModalSus(): void {
    this.isVisible = true;
  }


  showModal(): void {
    this.isVisible = true;
  }

  handleOk(): void {
    this.isVisible = false;
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  showModalAprobar(): void {

    this.isVisibleModalAprobar = true;
    this.keyAprobar.setValue('');
  }

  handleOkAprobar(): void {
    const self = this;

    if (this.keyAprobar.value != '') {

      const data = {
        token: JSON.parse(this.cookie.get('ud')).token,
        merchant: this.merchant,
        service: 'cashout/payroll/put',
        id: this.dataPublicID,
        data: {
          key: this.keyAprobar.value,
          metadata: JSON.stringify(JSON.parse(this.cookie.get('ud')).userDetails),
        }
      };

      self.api.api(data).toPromise().then((res: any) => {
        $('#charging').addClass('hide');

        console.log('Mensaje', res);

        let msj = '';

        if (res.Status == 'False') {
          msj = res.message;
        } else {
          $(this.eventTarget).addClass('deshabilitado');
        }

        self.success(msj);
        self.isVisibleModalAprobar = false;



      })
        .catch(e => {
          console.log(e);
          self.isVisibleModalAprobar = false;
          $('#charging').addClass('hide');
          if (e.status == 401) {
            this.cookie.set('ud', '');
            this.router.navigate(['/']);
          }
        });

    }

  }

  handleCancelAprobar(): void {
    this.isVisibleModalAprobar = false;
  }

  // UTILITIES

  getNameStatus(e) {
    if (e == 0) {
      e = 'o';
    }
    return this.api.getStatusByID(e.toString());
  }

  getNameStatusCashOut(e) {
    if (e == 0) {
      e = 'o';
    }
    return this.api.getStatusByIDCashOut(e.toString());
  }

  getNameStatusCashInSttelement(e) {
    return this.api.getStatusByIDCashInSttelement(e.toString());
  }

  getNameStatusCashOutOperations(e) {
    return this.api.getStatusByIDCashoutOperations(e.toString());
  }

  getStatusByIDPayroll(e) {
    return this.api.getStatusByIDPayroll(e.toString());
  }


  // FUNCTIONS EVENTS

  functionsEvent(f, data, event: any= false) {
    const info = {
      function: f,
      data,
      userDetails: JSON.parse(this.cookie.get('ud')).userDetails,
      event
    };
    this.dataOutput.emit(info);
  }

  functionsEvent2(f, data, event) {
    this.eventTarget = event.target;
    if ( f === 'Procesar') {
      this.dataOutput.emit(data);
    }

    const info = {
      function: f,
      data,
      userDetails: JSON.parse(this.cookie.get('ud')).userDetails,
      event
    };
    this.dataOutput.emit(info);
  }


  getActiveOrInactive(e) {

    if (e == true) {
      return 'Activo';
    } else {
      return 'Inactivo';
    }

  }

  refreshValues() {
    console.log('Recargar Pagina');
    location.reload();
    // this.allCashinOrdersData = this.allCashinOrders(this.cookie.get('merchant'));
    // this.allCashinOrdersDataTmp = this.allCashinOrders(this.cookie.get('merchant'));
  }

  getTypeBankAcount(e) {

    if (e == 1) {
      return 'Liquidaciones';
    } else {
      return 'Otro';
    }

  }


  copyText(val: string, cust) {


      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = val;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
      this.toastr.success(cust.FullName, 'Link Copiado');

  }

  changePage(page) {
    if (!!this.pagination) {
      this.changePageEmit.emit(page);
    }
  }
  changePageSize(size) {
    if (!!this.pagination) {
      this.changePageSizeEmit.emit(size);
    }
  }

  currentPageDataChange($event: any[]): void {
    this.listOfDisplayData = $event;
    this.refreshStatus();
  }

  checkAll(value: boolean): void {
    this.listOfDisplayData.filter(item => !item.disabled).forEach(item => (this.mapOfCheckedId[item.id] = value));
    this.refreshStatus();
  }

  refreshStatus(): void {
    this.isAllDisplayDataChecked = this.listOfDisplayData
      .filter(item => !item.disabled)
      .every(item => this.mapOfCheckedId[item.id]);
    this.isIndeterminate =
      this.listOfDisplayData.filter(item => !item.disabled).some(item => this.mapOfCheckedId[item.id]) &&
      !this.isAllDisplayDataChecked;
    this.numberOfChecked = this.infoTable.filter(item => this.mapOfCheckedId[item.id]).length
  }

  operateData(): void {
    this.selectedItemsEmit.emit(this.infoTable.filter(item => this.mapOfCheckedId[item.id]) ? this.infoTable.filter(item => this.mapOfCheckedId[item.id]) : []);
    
  }

}
