import { ITotaltable } from './../../../../../interfaces/ITotaltable';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';
import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import * as moment from 'moment';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit {

  lang: any = this.translate.getDefaultLang();

  public allCashinOrdersData: any = this.allCashinOrders(this.cookie.get('merchant'), { status: 14 });  // SOLO STATUS 14
  public filters: any = this.getFilters();
  public titlePage = 'Cash In > Pagos';
  public userDetails = JSON.parse(this.cookie.get('ud')).userDetails;
  public MerchantActive = JSON.parse(this.cookie.get('ud')).MerchantActive;
  public merchant: any = JSON.parse(this.cookie.get('ud')).MerchantActive[0].MerchantData.MerchantCode;
  public totaltable: ITotaltable = {
    countRow : 0,
    currency: '',
    totalCOP: 0,
    totalPEN: 0,
    TotalUSD: 0
  };
  from_date: string = moment().format('YYYY-MM-DD');
  to_date: string = moment().format('YYYY-MM-DD');


  constructor(private api: ApiService, private cookie: CookieService, public translate: TranslateService) {

  }

  ngOnInit() {
    this.changeMerchant();
    console.log(this.MerchantActive);
    this.translateChange();
    this.api.validateMerchantPermissions();
  }
  translateChange() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if (event.lang === 'en') {
        this.lang = 'en';
      } else if (event.lang === 'es') {
        this.lang = 'es';
      }
    });
  }

  changeMerchant() {
    this.api.merchant$.subscribe(m => {
      if (m.event === 'change-merchant') {
        this.allCashinOrdersData = this.allCashinOrders(m.data, { status: 14 }); // SOLO STATUS 14
        this.merchant = m.data;
        this.api.validateMerchantPermissions();
      }
    });
  }

  async allCashinOrders(merchant, dataInfo: any = false) {

    const data = {
      token: JSON.parse(this.cookie.get('ud')).token,
      merchant,
      service: 'cashin/transactions/',
      data: {
        from_date:  moment().format('YYYY-MM-DD'),
        to_date: moment().format('YYYY-MM-DD'),
      }
    };

    const headers = [
      {
        name: 'ID',
        key: 'PublicId',
        opc: true
      },

      {
        name: 'Monto',
        key: 'Amount',
        opc: true
      },
      {
        name: 'Moneda',
        key: 'CurrencyCode',
        opc: true
      },
      {
        name: 'Cliente',
        key: 'CustomerName',
        opc: true
      },
      {
        name: 'Código de pago',
        key: 'PaymentCode',
        opc: true
      },
      {
        name: 'Creación',
        key: 'created_at',
        opc: true
      },
      {
        name: 'Expiración',
        key: 'TxExpiration',
        opc: true
      },
      {
        name: 'Estado',
        key: 'LastStatus',
        opc: true
      },
      // {
      //   'name': 'Opciones',
      //   'key': 'opc',
      //   'opc': false,
      //   'functions': ['Ver detalle']
      // },
    ];

    if (dataInfo !== false) {
      data.service = 'cashin/transactions/WithParams';
      data.data = dataInfo;
    }
    const result = await this.api.api(data).toPromise() as any[];
    this.totaltable = {
      countRow : 0,
      currency: '',
      totalCOP: 0,
      totalPEN: 0,
      TotalUSD: 0
    };
    result.forEach((item, i) => {
      let laguage = 'es-CO';
      if (item.CurrencyCode === 'PEN') {
        laguage = 'es-PE';
        this.totaltable.totalPEN += Number(String(item.Amount).replace(',', '.'));
      }
      if (item.CurrencyCode === 'USD') {
        laguage = 'en-US';
        this.totaltable.TotalUSD += Number(String(item.Amount).replace(',', '.'));
      }
      if (item.CurrencyCode === 'COP') {
        this.totaltable.totalCOP += Number(String(item.Amount).replace(',', '.'));
      }
      const formatter = new Intl.NumberFormat(laguage, {
        style: 'decimal',
        currency: item.CurrencyCode,
        minimumFractionDigits: 2
      });
      result[i].Amount = formatter.format(Number(String(item.Amount).replace(',', '.')));
      // result[i].Amount = formatter.format(item.Amount);
    });
    this.totaltable.currency = 'COP';
    this.totaltable.countRow = result.length;
    return {
      headers,
      data: result,
      source: 'cashin-payments-orders'
    };

  }



  async getFilters() {

    return [
      {
        name: 'Periodo',
        key: 'period',
        type: 'period',
        value: [moment().startOf('month').toDate(), moment().toDate()],
      },
      {
        name: 'Buscar',
        key: 'button',
        type: 'button'
      },
      // {
      //   'name': 'Estado',
      //   'key': 'status',
      //   'type': 'select',
      //   'data': this.api.getStatus()
      // }

    ];

  }

  changeDataTable(data) {

    this.from_date = data.period ? moment(data.period[0]).format('YYYY-MM-DD') : '';
    this.to_date = data.period ? moment(data.period[1]).format('YYYY-MM-DD') : '';

    data.status = 14; // SOLO STATUS 14
    this.allCashinOrdersData = this.allCashinOrders(this.cookie.get('merchant'), {...data, from_date: this.from_date, to_date: this.to_date});
  }

  export() {

    const data = {
      token: JSON.parse(this.cookie.get('ud')).token,
      merchant: this.merchant,
      service: 'cashin/transactions/download/',
      data: {
        from_date: this.from_date,
        to_date: this.to_date,
        status: 14,  // SOLO STATUS 14
        language: this.lang
      }
    };
    console.log('EXPORT payments', data);

    this.api.api(data);
    $('#charging').addClass('hide');
  }

}
