import { Location } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BatchFileActivity, BatchFileFailed, BatchFileSuccess } from '../../../interfaces/response-batch-file-detail';
import { CustomerService } from '../../../services/customers.service';
import { TableColumn } from '../../partials/tables/custom-table/custom-table.component';

@Component({
  selector: 'app-batch-files-detail',
  templateUrl: './batch-files-detail.component.html',
  styleUrls: ['./batch-files-detail.component.scss']
})
export class BatchFilesDetailComponent implements OnInit {
  @ViewChild('statusNameTemplate', { static: true }) statusNameTemplate: TemplateRef<any>;
  protected id = this.aR.snapshot.params.id;
  protected isLoadingTable = false;
  protected activities: BatchFileActivity[] = [];
  protected successRecords: BatchFileSuccess[] = [];
  protected failedRecords: BatchFileFailed[] = [];
  protected columns: TableColumn[] = [];
  protected columnsRecords: TableColumn[] = [];

  constructor(private aR: ActivatedRoute, private customerService: CustomerService, private location: Location) { }

  ngOnInit() {
    this.getBatchFile();
    this.columns = [
      {
        key: 'batchfileActivityId',
        title: 'ID',
        type: 'text'
      },
      {
        key: 'status',
        title: 'Estado',
        type: 'template',
        templateRef: this.statusNameTemplate
      },
      {
        key: 'createdAt',
        title: 'Fecha de creación',
        type: 'date',
        format: 'dd/MM/yyyy HH:mm',
        classes: 'nowrap'
      },
    ];

    this.columnsRecords = [
      {
        key: 'batchfileRecordId',
        title: 'ID',
        type: 'text'
      },
      {
        key: 'status',
        title: 'Estado',
        type: 'template',
        templateRef: this.statusNameTemplate
      },
      {
        key: 'createdAt',
        title: 'Fecha de creación',
        type: 'date',
        format: 'dd/MM/yyyy HH:mm',
        classes: 'nowrap'
      },
    ];
  }

  goBack() {
    this.location.back();
  }

  getBatchFile() {
    this.isLoadingTable = true;
    this.customerService.getBatchFile(this.id).subscribe((data) => {
			this.activities = data.data.batchFileActivities;
      this.failedRecords = data.data.batchFileRecords.failed;
      this.successRecords = data.data.batchFileRecords.success;
      this.isLoadingTable = false;
		}, (error) => {
      this.isLoadingTable = false;
    });
  }
}
