import { Pipe, PipeTransform } from '@angular/core';
import { BadgeColor } from '../../shared/enums/badge-color';
import { STATUS_BATCH_FILES, StatusBatchFiles } from './status-batch-files';

@Pipe({
  name: 'statusNameBatchFiles'
})
export class StatusNameBatchFilesPipe implements PipeTransform {

  transform(status: StatusBatchFiles): any {
    return STATUS_BATCH_FILES[status];
  }

}


@Pipe({
	name: 'statusColorBatchFiles',
})
export class StatusColorBatchFilesPipe implements PipeTransform {
	transform(value: StatusBatchFiles): BadgeColor {
		switch (value) {
			case StatusBatchFiles.PENDING_PROCESSING:
				return BadgeColor.Gray;
			case StatusBatchFiles.PROCESS_SUCCESSFUL:
				return BadgeColor.Green;
			case StatusBatchFiles.PROCESS_FAILED:
				return BadgeColor.Red;
			case StatusBatchFiles.PROCESS_WITH_ERRORS:
				return BadgeColor.Yellow;
			case StatusBatchFiles.PENDING_APPROVAL:
				return BadgeColor.Blue;
			case StatusBatchFiles.APPROVED:
				return BadgeColor.Purple;
			default:
				return BadgeColor.Gray;
		}
	}
}