import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-filter-customer',
  templateUrl: './filter-customer.component.html',
  styleUrls: ['./filter-customer.component.scss']
})
export class FilterCustomerComponent implements OnInit {


    @Input() data: any;
    @Output() dataOutput = new EventEmitter<any>();

    form: FormGroup;
    items: FormArray;
    dataFields: any;
    isVisible = false;
    date = null;
    dateRange = [];
    titleModal: any = '';
    buttonDownload = false;
    range: any = null;
    period: any = new FormControl('0');

    constructor(private fb: FormBuilder, private cookie: CookieService, private api: ApiService, private router: Router) {}

    ngOnInit() {

      this.data.then((data: any) => {
        this.dataFields = data;
        const item: any = {};

        data.forEach((e: any) => {

          if (e.key === 'download') {
            this.buttonDownload = true;
          }

          const fromDate = moment('2019').startOf('month').format('YYYY-MM-DD');
          const toDate = moment().format('YYYY-MM-DD');

          if (Array.isArray(e.key)) {
            item.period = '3';
            item[e.key[0]] = fromDate;
            item[e.key[1]] = toDate;
          } else {
            if (e.type === 'select') {
              item[e.key] = '';
            } else {
              item[e.key] = '';
            }
          }

        });

        this.form = this.fb.group({
          items: this.fb.array([this.createItem(item)])
        });

      });

    }

    onChangePeriod(e) {

      moment.locale('es', {
        week: {
          dow: 1,
          doy: 4
        }
      });

      let fromDate = '';
      const toDate = moment().format('YYYY-MM-DD');

      switch (e.target.value) {
        case '0':
          fromDate = moment().format('YYYY-MM-DD');
          this.range = null;
          break;
        case '1':
          fromDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
          this.range = null;
          break;
        case '2':
          fromDate = moment().weekday(0).format('YYYY-MM-DD');
          this.range = null;
          break;
        case '3':
          fromDate = moment().startOf('month').format('YYYY-MM-DD');
          this.range = null;
          break;
        case '4':
          fromDate = moment().subtract(3, 'months').startOf('month').format('YYYY-MM-DD');
          this.range = null;
          break;
        case '5':
          this.showModal();
          break;
        default:
          break;
      }

      console.log('FROM: ', fromDate);
      console.log('TO: ', toDate);

      const values = this.fields.controls[0].value;
      values.from_date = fromDate;
      values.to_date = toDate;

      if (e.target.value !== '5') {
        this.dataOutput.emit(values);
      }
    }

    search(e) {
      this.dataOutput.emit({
        type: 'search',
        data: e
      });
    }

    processAditionalButton(e) {
      this.dataOutput.emit(e);
    }

    processAditionalButtonDownload(e: any) {
      this.dataOutput.emit({
        type: e,
        data: this.getData()
      });
    }

    process() {
      this.dataOutput.emit(this.getData());
    }

    getData() {
      const dataValues = (this.form.get('items') as FormArray).controls[0].value;
      return dataValues;
    }

    createItem(item: any): FormGroup {
      return this.fb.group(item);
    }

    get formData() { return (this.form.get('items') as FormArray).controls; }

    get fields() {
      return this.form.get('items') as FormArray;
    }


    // MODAL

    showModal(): void {
      this.isVisible = true;
    }

    handleOk(): void {
      this.isVisible = false;
      this.process();
    }

    handleCancel(): void {
      this.isVisible = false;
    }

    // DATE PICKER

    onChangeDate(result: Date): void {
      const fromDate = moment(result[0]).format('YYYY-MM-DD');
      const toDate = moment(result[1]).format('YYYY-MM-DD');
      const values = this.fields.controls[0].value;
      values.from_date = fromDate;
      values.to_date = toDate;
      this.fields.controls[0].setValue(values);

      this.range = fromDate + ' / ' + toDate;
      this.period.setValue('-1');

    }

}
