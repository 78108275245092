import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from './../../../services/api.service';
import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UploadChangeParam } from 'ng-zorro-antd/upload';
import * as XLSX from 'xlsx';



const { read, write, utils } = XLSX;
type AOA = any[][];

@Component({
  selector: 'app-new-payroll',
  templateUrl: './new-payroll.component.html',
  styleUrls: ['./new-payroll.component.scss']
})

export class NewPayrollComponent implements OnInit {

  titlePage = 'Cash Out > Nóminas > Importar';
  step = 1;
  data: AOA = [];
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  fileName = 'SheetJS.xlsx';
  headers: any = [];
  body: any = [];
  file: any;
  formData: FormData = new FormData();
  titleFile: String = 'Drag and drop your file';
  public merchant: any = JSON.parse(this.cookie.get('ud')).MerchantActive[0].MerchantData.MerchantCode;
  confirmation: any = null;
  Process = '';
  Error = '';


  constructor(private msg: NzMessageService, private api: ApiService, private cookie: CookieService, public router: Router) { }

  ngOnInit() {
    this.changeMerchant();
    this.api.validateMerchantPermissions();
  }

  changeMerchant() {
    this.api.merchant$.subscribe(m => {
      if (m.event === 'change-merchant') {
        this.merchant = m.data;
        this.api.validateMerchantPermissions();
      }

    });
  }


  onFileChange(evt: any) {

    this.file = evt.dataTransfer ? evt.dataTransfer.files[0] : evt.target.files[0];
    this.data = [];
    this.headers = [];
    this.body = [];
    this.step = 2;
    /* wire up file reader */
    const target: DataTransfer = (evt.target) as DataTransfer;
    if (target.files.length !== 1) { throw new Error('Cannot use multiple files'); }
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.data = (XLSX.utils.sheet_to_json(ws, { header: 1 })) as AOA;
      this.headers = this.data[0];
      for (let i = 1; i < this.data.length; i++) {
        this.body.push(this.data[i]);
      }

    };
    reader.readAsBinaryString(target.files[0]);

  }

  next() {
    const self = this;
    console.log(this.file);


    const file = new FormData();

    if (this.file) {
      file.append('file', this.file, this.file.name);
      file.append('metadata', JSON.stringify(JSON.parse(this.cookie.get('ud')).userDetails));
      file.append('merchant_code', this.merchant);
    }

    if (this.step == 2) {

      const data = {
        token: JSON.parse(this.cookie.get('ud')).token,
        service: 'cashout/payroll',
        data: file
      };

      this.api.api(data).toPromise().then((data: any) => {
        $('#charging').addClass('hide');
        this.Process = data.Process;
        this.Error = data.Error;

        if (data.Error > 0) {
          this.confirmation = false;


        } else {
          this.confirmation = true;
        }
      }).catch(error => {
        $('#charging').addClass('hide');
        if (error.status == 401) {
          console.log(error);
          // this.cookie.set('ud', '');
          // this.router.navigate(['/']);
        }
      });


    }
  }

  // fileChange(event) {
  //   let fileList: FileList = event.target.files;
  //   if (fileList.length > 0) {
  //     let file: File = fileList[0];
  //     this.formData.append('uploadFile', file, file.name);
  //   }
  // }





}


