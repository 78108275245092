import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
declare var $: any;

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  user: any = (this.cookie.get('ud') != '') ? JSON.parse(this.cookie.get('ud')) : null;
  imageProfile: any = 'assets/img/editProfile.png';

  @Output() dataOutput = new EventEmitter<any>();

  public userDetails: any;
  public MerchantActive: any;
  public merchant: any;
  public isLogged: any;
  lang: any = 'es';
  langSelect = '';

  constructor(public cookie: CookieService, public router: Router, public api: ApiService, public translate: TranslateService) {

    if (this.cookie.get('ud') && this.cookie.get('ud') !== '') {
      this.userDetails = JSON.parse(this.cookie.get('ud')).userDetails;
      this.MerchantActive = JSON.parse(this.cookie.get('ud')).MerchantActive;

      if (!this.MerchantActive) {
        this.cookie.set('ud', '');
        this.router.navigate['/login'];
      } else {
        // this.merchant = new FormControl(JSON.parse(this.cookie.get('ud')).MerchantActive[0].MerchantData.MerchantCode);
        this.merchant = new FormControl(JSON.parse(this.cookie.get('merchant')));
      }

    }

  }

  ngOnInit() {

      if (this.user) {
        this.imageProfile = this.user.userDetails.image;
      }

      this.verifySession();
      this.translateChange();

      if (this.translate.currentLang !== undefined) {
        this.langSelect = this.translate.currentLang;
        this.lang = this.translate.currentLang;
      }

  }

  onClickMenu(e) {

    $('#nav').hide();
    $('#menu2').show();

  }

  exit() {

    if (this.cookie.get('ud') != '') {
      const data = {
        token: JSON.parse(this.cookie.get('ud')).token,
        service: 'auth/logout'
      };
      this.api.api(data).subscribe((result: any) => {
        if (result.success) {
          this.cookie.set('ud', '');
          this.router.navigate(['/login']);
        }
      },
        error => {
          if (error.error.message == 'Token has expired') {
            this.cookie.set('ud', '');
            this.router.navigate(['/login']);
          } else {
            console.log(error);
          }
        });
    } else {
      this.cookie.set('ud', '');
      this.router.navigate(['/login']);
    }

  }

  translateChange() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      console.log('EVENT LANG', event.lang);
      this.langSelect = event.lang;
    });
  }
  changeMerchant() {
    if (this.merchant) {
      this.cookie.set('merchant', this.merchant.value);
    }
    this.api.validateMerchantPermissions();
    this.api.merchant$.emit({
      event: 'change-merchant',
      data: this.merchant.value,
      dataMerchant: this.MerchantActive
    });
  }

  verifySession() {
    if (this.cookie.get('ud') === '') {
      this.isLogged = true;
      this.router.navigate(['/login']);
    }
  }

  changeLang(language) {
    this.lang = language;
    this.translate.use(language);
  }

}



