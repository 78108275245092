import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Component, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { UtilitiesService } from '../../../services/utilities.service';

declare var $: any;

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
    urlredirectweinvoice = '';
    urlredirectwecourier = '';

    @Output() dataOutput = new EventEmitter<any>();

    public userDetails: any;
    public MerchantActive: any;
    public merchant: any;
    public interval: any;
    public currencyCodesCurrent: string;
    public isLogged: any;
    public lang: any = 'es';
    public langSelect = '';
    public merchantId: string;
    public dataUser: string;
    public isVisibleModal = false;
    public balanceDetails: any = {};
    public currencyCodes: any[] = [];

    constructor(public cookie: CookieService, public router: Router, public api: ApiService, public translate: TranslateService, private utilitiesService: UtilitiesService, private modalService: NzModalService) {

        this.merchantId = this.cookie.get('merchant');
        this.dataUser = this.cookie.get('ud');

        if (this.cookie.get('ud') && this.cookie.get('ud') !== '') {
            this.userDetails = JSON.parse(this.cookie.get('ud')).userDetails;
            this.MerchantActive = JSON.parse(this.cookie.get('ud')).MerchantActive;

            if (!this.MerchantActive) {
                this.cookie.set('ud', '');
                this.router.navigate['/login'];
            } else {
                this.merchant = new FormControl(JSON.parse(this.cookie.get('merchant')));
            }

        }

    }

    ngOnInit() {
        this.currencyCodes = this.utilitiesService.getSupportedCurrencyCodes();
        this.verifySession();
        this.translateChange();
        this.changeMerchant();
        this.currencyCodesCurrent = this.currencyCodes[0].CurrencyCode;
        this.getAvailableBalance();
        this.interval = setInterval(() => {
            this.getAvailableBalance();
        }, 30000);

        if (this.translate.currentLang !== undefined) {
            this.langSelect = this.translate.currentLang;
            this.lang = this.translate.currentLang;
        }
    }

    ngOnDestroy() {
        clearInterval(this.interval);
    }

    translateChange() {
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            if (event.lang === 'en') {
                this.langSelect = 'en';
            } else if (event.lang === 'es') {
                this.langSelect = 'es';
            }
        });
    }

    onClickMenu(e) {
        $('#nav').show();
        $('#menu2').hide();
    }

    changeMerchant() {
        this.api.merchant$.subscribe(m => {
            if (m.event === 'change-merchant') {
                clearInterval(this.interval);
                this.merchantId = m.data;
                this.api.validateMerchantPermissions();
                this.currencyCodes = this.utilitiesService.getSupportedCurrencyCodes();
                this.currencyCodesCurrent = this.currencyCodes[0].CurrencyCode;
                this.getAvailableBalance();
                this.interval = setInterval(() => {
                    this.getAvailableBalance();
                }, 30000);
            }
        });
    }

    verifySession() {
        if (this.cookie.get('ud') === '') {
            this.isLogged = true;
            this.router.navigate(['/login']);
        }
    }

    changeLang(language) {
        this.lang = language;
        this.translate.use(language);
    }

    getAvailableBalance() {
        const url = `transaction/availableBalance?merchantCode=${this.merchantId}&currencyCode=${this.currencyCodesCurrent}`;
        this.api.queryGet(url).subscribe(
            (data: any) => {
                if (data.status) {
                    this.balanceDetails = data.data;
                }
            }
        );
    }

    newIntervalGetAvailableBalance(currencyCode) {
        this.currencyCodesCurrent = currencyCode;
        clearInterval(this.interval);
        this.getAvailableBalance();
        this.interval = setInterval(() => {
            console.log('newIntervalGetAvailableBalance');
            this.getAvailableBalance();
        }, 30000);
    }

    onDispersion() {
        this.isVisibleModal = true;
    }

    emitOkModal(values) {
        this.isVisibleModal = false;
        this.sendDispersion(values);
    }

    emitCloseModal() {
        this.isVisibleModal = false;
    }

    sendDispersion(values) {
        this.utilitiesService.updateLoading(true);
        const url = `transaction/transferCashin`;
        const data = {
            Amount: values.amount,
            MerchantCode: this.merchantId,
            CurrencyCode: values.currency,
            BankCode: values.bank
        };
        this.api.queryPost(url, data).subscribe(
            (response: any) => {
                this.isVisibleModal = false;
                this.utilitiesService.updateLoading(false);
                if (response.status) {
                    this.modalService.success({
                        nzTitle: 'Success',
                        nzContent: 'Dispersion was successful'
                    });
                }
            }
        );
    }

    urlredirectWeinvoice() {
        const redirectUrl = `${this.urlredirectweinvoice}/${this.merchantId}/${this.dataUser.replace(/\//g, 'kxzy!')}`;
        window.location.href = redirectUrl;
    }

    urlredirectWecourier() {
        const redirectUrl = `${this.urlredirectwecourier}/${this.merchantId}/${this.dataUser.replace(/\//g, 'kxzy!')}`;
        window.location.href = redirectUrl;
    }

}
