import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import * as $ from 'jquery';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';
import { ITotaltable } from '../../../../interfaces/ITotaltable';

@Component({
  selector: 'app-payments-orders',
  templateUrl: './payments-orders.component.html',
  styleUrls: ['./payments-orders.component.scss']
})
export class PaymentsOrdersComponent implements OnInit {

  lang: any = this.translate.getDefaultLang();

  public allCashinOrdersData: any = Promise.resolve([]);
  public filters: any = this.getFilters();
  public userDetails = JSON.parse(this.cookie.get('ud')).userDetails;
  public MerchantActive = JSON.parse(this.cookie.get('ud')).MerchantActive;
  public merchant: any = JSON.parse(this.cookie.get('ud')).MerchantActive[0].MerchantData.MerchantCode;
  public totaltable: ITotaltable = {
    countRow : 0,
    currency: '',
    totalCOP: 0,
    totalPEN: 0,
    TotalUSD: 0
  };
  isDetailExportVisible = false;
  isNewPaymentOrderModalOpen = false;
  formPaymentOrder: FormGroup;
  isInstruccionesVisible = false;
  creationResponse = 'test';
  showCompletedFieldErrorMessage = false;
  from_date: string = moment().format('YYYY-MM-DD');
  to_date: string = moment().format('YYYY-MM-DD');
  countryCod: string = null;
  isLoadingTable = false;
  currentFilters: any;
  pageSize = 10;

  constructor(private api: ApiService, private cookie: CookieService, private router: Router, public translate: TranslateService) {
    this.formPaymentOrder =   this.createFormGroup();
  }

  ngOnInit() {
    this.changeMerchant();
    this.translateChange();
    this.api.validateMerchantPermissions();
  }

  createFormGroup() {
    return new FormGroup({
      MerchantSalesID: new FormControl('', [Validators.required]),
      Detail: new FormControl(''),
      Amount: new FormControl('', [Validators.required]),
      CurrencyCode: new FormControl('', [Validators.required]),
      TimeExpired: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
      OkURL: new FormControl(''),
      ErrorURL: new FormControl(''),
      Channel: new FormControl('', [Validators.required]),
      FirstName: new FormControl('', [Validators.required]),
      LastName: new FormControl('', [Validators.required]),
      CountryCode: new FormControl('', [Validators.required]),
      DocType: new FormControl('', [Validators.required]),
      DocNumber: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(8)]),
      Email: new FormControl(''),
      Mobile: new FormControl('', [Validators.pattern('^[0-9]*$'),
        Validators.minLength(9), Validators.maxLength(9)])
    });
  }

  translateChange() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if (event.lang === 'en') {
        this.lang = 'en';
      } else if (event.lang === 'es') {
        this.lang = 'es';
      }
    });
  }

  changeMerchant() {
    this.api.merchant$.subscribe(m => {
      if (m.event === 'change-merchant') {
        this.allCashinOrdersData = this.allCashinOrders(m.data);
        this.merchant = m.data;
        this.api.validateMerchantPermissions();
      }
    });
  }

  async allCashinOrders(merchant, dataInfo: any = false) {
    const data = {
      token: JSON.parse(this.cookie.get('ud')).token,
      merchant,
      service: 'cashin/transactions/',
      data: {
        from_date:  moment().format('YYYY-MM-DD'),
        to_date: moment().format('YYYY-MM-DD'),
        per_page: this.pageSize
      }
    };

    const headers = [
      {
        name: 'ID',
        key: 'PublicId',
        translationKey: 'table.ID',
        opc: true
      },
      {
        name: 'Detalle',
        key: 'DetailTransaction',
        translationKey: 'table.Detail',
        opc: true
      },
      {
        name: 'Monto',
        key: 'Amount',
        translationKey: 'table.Amount',
        opc: true
      },
      {
        name: 'Moneda',
        key: 'CurrencyCode',
        translationKey: 'table.Currency',
        opc: true
      },
      {
        name: 'Pais',
        key: 'CountryCode',
        translationKey: 'table.CountryCode',
        opc: true
      },
      {
        name: 'Cliente',
        key: 'CustomerName',
        translationKey: 'table.Client',
        opc: true
      },
      {
        name: 'Código de pago',
        key: 'PaymentCode',
        translationKey: 'table.PaymentCode',
        opc: true
      },
      {
        name: 'Creación',
        key: 'created_at',
        translationKey: 'table.Creation',
        opc: true
      },
      {
        name: 'Expiración',
        key: 'TxExpiration',
        translationKey: 'table.Expiration',
        opc: true
      },
      {
        name: 'Devoluciones',
        key: 'Refunds',
        translationKey: 'table.Refunds',
        opc: true
      },
      {
        name: 'Estado',
        key: 'LastStatus',
        translationKey: 'table.Status',
        opc: true
      },
      {
        name: 'Fee',
        key: 'Fee',
        translationKey: 'table.Fee',
        opc: true
      },
      {
        name: 'Opciones',
        key: 'opc',
        translationKey: 'table.Options',
        opc: true,
        functions: [{function: 'process', translationKey: 'table.Process'}]
      },
    ];

    if (dataInfo !== false) {
      data.service = 'cashin/transactions/WithParams';
      data.data = dataInfo;
      data.data = {...dataInfo, per_page: this.pageSize};
    }
    this.isLoadingTable = true;
    const result = await this.api.api(data).toPromise() as any;
    this.isLoadingTable = false;
    this.totaltable = {
      countRow : 0,
      currency: '',
      totalCOP: 0,
      totalPEN: 0,
      TotalUSD: 0
    };
    return {
      headers,
      data: result,
      source: 'cashin-payments-orders'
    };

  }



  async getFilters() {
    const data = {
      token: JSON.parse(this.cookie.get('ud')).token,
      merchant: '',
      service: 'countries',
      data: null
    };

    let countryData = [];
    let result;
     try {
       result = await this.api.api(data).toPromise() as any;
       countryData = result.data || [];
     } catch (error) {
      countryData = [];
     }

    const dataSelectCountry = [ ];
    countryData.forEach(item => {
      dataSelectCountry.push({
                                  name: item.CountryName,
                                  value: item.IsoCode3,
                              });
    });
    return [
      {
        name: 'Periodo',
        key: 'period',
        type: 'period',
        value: [moment().startOf('month').toDate(), moment().toDate()],
      },
      {
        name: 'Estado',
        key: 'status',
        type: 'select',
        placeHolder: 'Estado',
        data: this.api.getStatus()
      },
      {
        name: 'Pais',
        key: 'contry_cod',
        type: 'select',
        placeHolder: 'País',
        data:  dataSelectCountry
      },
      {
        name: 'Buscar',
        key: 'button',
        type: 'button'
      },

    ];

  }

  processTransaction(data) {
    const DATA: any = data.data;

    if (DATA.LastStatus === 13) {
      const request = {
        token: JSON.parse(this.cookie.get('ud')).token,
        merchant: this.merchant,
        service: 'cashin/transactions/confirm_recharge',
        type: 'post',
        data: {
          PublicId: DATA.PublicId
        }
      };

      this.api.api(request).toPromise().then(() => {
        $('#charging').addClass('hide');
        this.refreshValues();
      })
      .catch(e => {
        $('#charging').addClass('hide');
        if (e.status === 401 || e.status === 0) {
          this.cookie.set('ud', '');
          this.router.navigate(['/']);
        }
      });

    } else {
      alert('Esta opción esta desactivada.');
    }

  }

  changePage(page) {
    this.allCashinOrdersData = this.allCashinOrders(
        this.cookie.get('merchant'), {...this.currentFilters, page}
    );
  }

  changePageSize(size) {
    this.pageSize = size;
    this.allCashinOrdersData = this.allCashinOrders(
        this.cookie.get('merchant'), {...this.currentFilters, per_page: this.pageSize}
    );
  }

  changeDataTable(data) {
    this.from_date = data.period ? moment(data.period[0]).format('YYYY-MM-DD') : '';
    this.to_date = data.period ? moment(data.period[1]).format('YYYY-MM-DD') : '';
    this.countryCod = data.contry_cod ? data.contry_cod : null;

    if (data.type && data.type === 'search') {
      this.allCashinOrdersData = this.allCashinOrders(
          this.cookie.get('merchant'), {...data, from_date: this.from_date, to_date: this.to_date, find_by_filters: data.data}
      );
    } else {
      this.currentFilters = {...data, from_date: this.from_date, to_date: this.to_date};
      this.allCashinOrdersData = this.allCashinOrders(
          this.cookie.get('merchant'),
          {...data, from_date: this.from_date, to_date: this.to_date}
      );
    }
  }

  export() {
    const data = {
      token: JSON.parse(this.cookie.get('ud')).token,
      merchant: this.merchant,
      service: 'cashin/transactions/download/',
      data: {
        from_date: this.from_date,
        to_date: this.to_date,
        language: this.lang,
        contry_cod: this.countryCod
      }
    };
    this.api.api(data);
    $('#charging').addClass('hide');
    this.toggleDetailExport();
  }

  toggleDetailExport() {
    this.isDetailExportVisible = !this.isDetailExportVisible;
  }

  exportConcar() {
    // CONFIRM SERVICE AND SERVICE NAME

    // let data = {
    //   token: JSON.parse(this.cookie.get('ud')).token,
    //   merchant: this.merchant,
    //   service: 'cashin/transactions/download/',
    //   data: {
    //     from_date: $('#f_start').val(),
    //     to_date: $('#f_end').val(),
    //     language: this.lang
    //   }
    // }

    // this.api.api(data);
    // $('#charging').addClass('hide');
    this.toggleDetailExport();
  }

  openCreatePaymentOrder() {
    this.isNewPaymentOrderModalOpen = !this.isNewPaymentOrderModalOpen;
  }

  handleCancel() {
    this.isInstruccionesVisible = false;
    this.formPaymentOrder =   this.createFormGroup();
    this.isNewPaymentOrderModalOpen = !this.isNewPaymentOrderModalOpen;
    this.showCompletedFieldErrorMessage = false;
    this.refreshValues();
  }

  handleErrors() {

    if (this.formPaymentOrder.controls.Amount.status === 'INVALID') {
      this.formPaymentOrder.controls.Amount.markAsDirty();
      this.formPaymentOrder.controls.Amount.markAllAsTouched();
    }

    if (this.formPaymentOrder.controls.Channel.status === 'INVALID') {
      this.formPaymentOrder.controls.Channel.markAsDirty();
      this.formPaymentOrder.controls.Channel.markAllAsTouched();
    }

    if (this.formPaymentOrder.controls.CountryCode.status === 'INVALID') {
      this.formPaymentOrder.controls.CountryCode.markAsDirty();
      this.formPaymentOrder.controls.CountryCode.markAllAsTouched();
    }

    if (this.formPaymentOrder.controls.CurrencyCode.status === 'INVALID') {
      this.formPaymentOrder.controls.CurrencyCode.markAsDirty();
      this.formPaymentOrder.controls.CurrencyCode.markAllAsTouched();
    }

    if (this.formPaymentOrder.controls.Detail.status === 'INVALID') {
      this.formPaymentOrder.controls.Detail.markAsDirty();
      this.formPaymentOrder.controls.Detail.markAllAsTouched();
    }

    if (this.formPaymentOrder.controls.DocNumber.status === 'INVALID') {
      this.formPaymentOrder.controls.DocNumber.markAsDirty();
      this.formPaymentOrder.controls.DocNumber.markAllAsTouched();
    }

    if (this.formPaymentOrder.controls.DocType.status === 'INVALID') {
      this.formPaymentOrder.controls.DocType.markAsDirty();
      this.formPaymentOrder.controls.DocType.markAllAsTouched();
    }

    if (this.formPaymentOrder.controls.Email.status === 'INVALID') {
      this.formPaymentOrder.controls.Email.markAsDirty();
      this.formPaymentOrder.controls.Email.markAllAsTouched();
    }

    if (this.formPaymentOrder.controls.ErrorURL.status === 'INVALID') {
      this.formPaymentOrder.controls.ErrorURL.markAsDirty();
      this.formPaymentOrder.controls.ErrorURL.markAllAsTouched();
    }

    if (this.formPaymentOrder.controls.FirstName.status === 'INVALID') {
      this.formPaymentOrder.controls.FirstName.markAsDirty();
      this.formPaymentOrder.controls.FirstName.markAllAsTouched();
    }

    if (this.formPaymentOrder.controls.LastName.status === 'INVALID') {
      this.formPaymentOrder.controls.LastName.markAsDirty();
      this.formPaymentOrder.controls.LastName.markAllAsTouched();
    }

    if (this.formPaymentOrder.controls.MerchantSalesID.status === 'INVALID') {
      this.formPaymentOrder.controls.MerchantSalesID.markAsDirty();
      this.formPaymentOrder.controls.MerchantSalesID.markAllAsTouched();
    }

    if (this.formPaymentOrder.controls.Mobile.status === 'INVALID') {
      this.formPaymentOrder.controls.Mobile.markAsDirty();
      this.formPaymentOrder.controls.Mobile.markAllAsTouched();
    }

    if (this.formPaymentOrder.controls.OkURL.status === 'INVALID') {
      this.formPaymentOrder.controls.OkURL.markAsDirty();
      this.formPaymentOrder.controls.OkURL.markAllAsTouched();
    }

    if (this.formPaymentOrder.controls.TimeExpired.status === 'INVALID') {
      this.formPaymentOrder.controls.TimeExpired.markAsDirty();
      this.formPaymentOrder.controls.TimeExpired.markAllAsTouched();
    }

  }

  handleOk() {
    if (this.formPaymentOrder.status === 'INVALID') {
      this.showCompletedFieldErrorMessage = true;
      this.handleErrors();
    } else {
      this.showCompletedFieldErrorMessage = false;
      const data = {
        token: JSON.parse(this.cookie.get('ud')).token,
        merchant: this.merchant,
        service: 'cashin/transactions/post',
        type: 'post',
        data: {
          MerchantCode: this.merchant,
          MerchantSalesID: this.MerchantSalesID.value,
          Detail: this.Detail.value,
          Amount: this.Amount.value,
          CurrencyCode: this.CurrencyCode.value,
          TimeExpired: this.TimeExpired.value,
          OkURL: this.OkURL.value,
          ErrorURL: this.ErrorURL.value,
          Channel: this.Channel.value,
          FirstName: this.FirstName.value,
          LastName: this.LastName.value,
          CountryCode: this.CountryCode.value,
          DocType: this.DocType.value,
          DocNumber: this.DocNumber.value,
          Email: this.Email.value,
          Mobile: this.Mobile.value,
          Metadata: JSON.stringify(JSON.parse(this.cookie.get('ud')).userDetails),

        }
      };

      if (!this.isInstruccionesVisible) {
        // this.api.api(data).toPromise().then((res: any) => {
        //   this.isInstruccionesVisible = true;
        //   this.creationResponse = res.UrlRedirect;
        // });

        this.api.api(data).toPromise().then((res: any) => {
          this.isInstruccionesVisible = true;
          this.creationResponse = res.UrlRedirect;
          $('#charging').addClass('hide');
          this.refreshValues();
        })
        .catch(e => {
          console.log(e);
          $('#charging').addClass('hide');
          if (e.status === 401 || e.status === 0) {
            this.cookie.set('ud', '');
            this.router.navigate(['/']);
          }
        });

      } else {
        this.handleCancel();
        this.refreshValues();
        this.creationResponse = '';
      }
    }
  }

  refreshValues() {
    this.allCashinOrdersData = this.allCashinOrders(this.cookie.get('merchant'));
  }

  get MerchantSalesID() { return this.formPaymentOrder.get('MerchantSalesID'); }
  get Detail() { return this.formPaymentOrder.get('Detail'); }
  get Amount() { return this.formPaymentOrder.get('Amount'); }
  get CurrencyCode() { return this.formPaymentOrder.get('CurrencyCode'); }
  get TimeExpired() { return this.formPaymentOrder.get('TimeExpired'); }
  get OkURL() { return this.formPaymentOrder.get('OkURL'); }
  get ErrorURL() { return this.formPaymentOrder.get('ErrorURL'); }
  get Channel() { return this.formPaymentOrder.get('Channel'); }
  get FirstName() { return this.formPaymentOrder.get('FirstName'); }
  get LastName() { return this.formPaymentOrder.get('LastName'); }
  get CountryCode() { return this.formPaymentOrder.get('CountryCode'); }
  get DocType() { return this.formPaymentOrder.get('DocType'); }
  get DocNumber() { return this.formPaymentOrder.get('DocNumber'); }
  get Email() { return this.formPaymentOrder.get('Email'); }
  get Mobile() { return this.formPaymentOrder.get('Mobile'); }

}
