import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as $ from 'jquery';
import { NzModalService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { AccessService } from '../../../services/access.service';
import { AuthService } from './../../../services/auth.service';

export interface ResponseDataQR {
	statusCode: number;
	status: boolean;
	message: string;
	data: string;
	metadata: any;
}

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    response: any = false;
    public isLogged = false;
    formLogin: FormGroup;
    lang = 'es';
    isLoading = false;
    public dataLogin: string;
    public merchant: string;
    protected ud: any;

    protected isVisibleTokenModal = false;
    protected showQrGoogle = false;
    protected qrAuthGoogle = '';
    protected isOkLoading = false;
    protected otpControl = new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]);

    constructor(
        private aR: ActivatedRoute,
        private router: Router,
        private cookie: CookieService,
        private authService: AuthService,
        public translate: TranslateService,
        private accessService: AccessService,
        private nzModalService: NzModalService
    ) {
        this.formLogin = this.createFormGroup();
        if (this.aR.snapshot.paramMap.get('data')) {
            this.dataLogin = this.aR.snapshot.paramMap.get('data').replace(/(kxzy!)/g, '/');
        }
        this.merchant = this.aR.snapshot.paramMap.get('merchant');

    }

    createFormGroup() {
        return new FormGroup({
            email: new FormControl('', [Validators.required, Validators.email]),
            password: new FormControl('', [Validators.required, Validators.minLength(6)]),
        });
    }

    onLogin(): void {
        if (this.formLogin.invalid) {
            console.log('Formulario inválido');
            
            this.formLogin.markAllAsTouched();
            return;
          }

        this.isOkLoading = true;

        const data = {
            email: this.formLogin.get('email').value,
            password: this.formLogin.get('password').value,
            service: 'auth/login'
        };

        this.authService.api(data).subscribe((result: any) => {
                if (result.success !== 'Error en credenciales') {
                    this.ud = result;
                    this.loginAccess(data)
                } else {
                    this.isOkLoading = false;
                    this.response = 1;
                }
            },
            error => {
                this.isOkLoading = false;
                if (error.status === 401) {
                    this.response = 2;
                }
            });

    }


    ngOnInit() {
        this.verifySession();
    }

    verifySession() {
        if (this.cookie.get('ud') !== '') {
            this.isLogged = true;
            this.router.navigate(['/']);
        } else {
            if (this.dataLogin && this.merchant) {
                this.cookie.set('ud', this.dataLogin);
                this.cookie.set('merchant', this.merchant);
                this.isLogged = true;
                this.router.navigate(['/']);
            }
        }
    }

    onResetForm() {
        this.formLogin.reset();
    }

    seePassword(e) {
        if ($('#password').attr('type') === 'password') {
            $('#password').attr('type', 'text');
        } else {
            $('#password').attr('type', 'password');
        }
    }

    loginAccess({email, password}) {
        const userCoreAccess = {
            email,
            password,
            service: 'cashin'
        }
        this.accessService.login(userCoreAccess).subscribe((res: any) => {      
          localStorage.setItem('authAccess', JSON.stringify(res));
          this.validateRegisterUserGoogle(this.ud.userDetails.profileid);
        }, () => {
            this.isOkLoading = false;
        })
      }
    
      validateRegisterUserGoogle(userId: number) {
        this.accessService.isUseTokenWithUserIdGoogle(userId).subscribe((res: ResponseDataQR) => {
          this.isOkLoading = false;
          if(!res.data){
            this.showQrGoogle = true;
            this.isOkLoading = true;
            this.accessService.getQrWithUseridGoogle(userId, false).subscribe(resulQr => {
              this.isOkLoading = false;
              this.qrAuthGoogle = resulQr.data;
              this.isVisibleTokenModal = true;
            });
          } else {
            this.showQrGoogle = false;
            this.qrAuthGoogle = '';
            this.isVisibleTokenModal = true;
          }
        });
      }

      handleCancelTokenModal() {
        this.isVisibleTokenModal = false;
        this.isOkLoading = false;
        this.otpControl.reset();
      }
    
      handleOkTokenModal() {
        this.isOkLoading = true;
        this.accessService.validateAuthToken(this.otpControl.value, this.ud.userDetails.profileid).subscribe((res: any) => {
            this.isOkLoading = false;
            this.isVisibleTokenModal = false;
            this.cookie.set('ud', JSON.stringify(this.ud));
            this.cookie.set('merchant', this.ud.MerchantActive[0].MerchantData.MerchantCode);
            this.router.navigate(['/']);
        }, (err) => {
            this.isOkLoading = false;
            this.isVisibleTokenModal = false;
            this.otpControl.reset();
        });
      }

      error(error): void {
        this.nzModalService.error({nzTitle: 'Mensaje de error', nzContent: error});
      }


    changeLang(language) {
        this.lang = language;
        this.translate.use(language);
    }

    get email() {
        return this.formLogin.get('email');
    }

    get password() {
        return this.formLogin.get('password');
    }

}
