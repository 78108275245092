import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';
import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ITotaltable } from '../../../../interfaces/ITotaltable';
import * as moment from 'moment';

@Component({
    selector: 'app-operations',
    templateUrl: './operations.component.html',
    styleUrls: ['./operations.component.scss']
})
export class OperationsComponent implements OnInit {

    lang: any = this.translate.getDefaultLang();
    isLoading = false;

    public allCashinOrdersData: any = Promise.resolve([]);
    public filters: any = this.getFilters();
    public titlePage = 'Cash Out > Operaciones';
    public userDetails = JSON.parse(this.cookie.get('ud')).userDetails;
    public MerchantActive = JSON.parse(this.cookie.get('ud')).MerchantActive;
    public merchant: any = JSON.parse(this.cookie.get('ud')).MerchantActive[0].MerchantData.MerchantCode;
    public totaltable: ITotaltable = {
        countRow: 0,
        currency: '',
        totalCOP: 0,
        totalPEN: 0,
        TotalUSD: 0
    };
    from_date: string = moment().format('YYYY-MM-DD');
    to_date: string = moment().format('YYYY-MM-DD');
    currentFilters: any;
    pageSize = 10;
    countryCod: string = null;

    constructor(private api: ApiService, private cookie: CookieService, public translate: TranslateService) {

    }

    ngOnInit() {
        this.changeMerchant();
        this.translateChange();
        this.api.validateMerchantPermissions();
    }

    translateChange() {
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            if (event.lang === 'en') {
                this.lang = 'en';
            } else if (event.lang === 'es') {
                this.lang = 'es';
            }
        });
    }

    changeMerchant() {
        this.api.merchant$.subscribe(m => {
            if (m.event == 'change-merchant') {
                this.allCashinOrdersData = this.allCashinOrders(m.data);
                this.merchant = m.data;
                this.api.validateMerchantPermissions();
            }
        });
    }

    async allCashinOrders(merchant, dataInfo: any = false) {
        const data = {
            token: JSON.parse(this.cookie.get('ud')).token,
            merchant,
            service: 'cashout/transactions/',
            data: {
                from_date: moment().format('YYYY-MM-DD'),
                to_date: moment().format('YYYY-MM-DD'),
                per_page: this.pageSize
            }
        };

        const headers = [
            {
                name: 'ID',
                key: 'PublicID',
                opc: true
            },

            {
                name: 'ID Comercio',
                key: 'MerchantReference',
                opc: true
            },
            {
                name: 'Monto',
                key: 'Amount',
                opc: true
            },
            {
                name: 'Moneda',
                key: 'CurrencyCode',
                opc: true
            },
            {
                name: 'Pais',
                key: 'CountryCode',
                translationKey: 'table.CountryCode',
                opc: true
            },
            {
                name: 'Cliente',
                key: 'Customer-Info-FullName',
                opc: true
            },
            {
                name: 'Creación',
                key: 'TxCreation',
                opc: true
            },
            {
                name: 'Banco',
                key: 'Customer-InfoBank-CustomerBank',
                opc: true
            },
            {
                name: 'Estado',
                key: 'LastStatus',
                opc: true
            },
            {
                name: 'Fee',
                key: 'Fee',
                translationKey: 'table.Fee',
                opc: true
            },
            {
                name: 'Respuesta del Banco',
                key: 'ResponseBank',
                opc: true
            },
            // {
            //   'name': 'Opciones',
            //   'key': 'opc',
            //   'opc': false,
            //   'functions': ['Ver detalle']
            // },
        ];
        if (dataInfo !== false) {
            data.service = 'cashout/transactions/WithParams';
            data.data = {...dataInfo, per_page: this.pageSize};
        }

        this.isLoading = true;
        const result = await this.api.api(data).toPromise() as any;
        this.isLoading = false;

        this.totaltable = {
            countRow: 0,
            currency: '',
            totalCOP: 0,
            totalPEN: 0,
            TotalUSD: 0
        };

        this.totaltable.currency = 'COP';
        this.totaltable.countRow = Array.isArray(result) ? result.length : result.data.length;
        return {
            headers,
            data: result,
            source: 'cashout-operations'
        };

    }


    async getFilters() {
        const data = {
            token: JSON.parse(this.cookie.get('ud')).token,
            merchant: '',
            service: 'countries',
            data: null
        };

        const result = await this.api.api(data).toPromise() as any;
        const countryData = result.data;

        const dataSelectCountry = [];
        countryData.forEach(item => {
            dataSelectCountry.push({
                name: item.CountryName,
                value: item.IsoCode3,
            });
        });
        return [
            {
                name: 'Periodo',
                key: 'period',
                type: 'period',
                value: [moment().startOf('month').toDate(), moment().toDate()]
            },
            {
                name: 'Estado',
                key: 'status',
                type: 'select',
                placeHolder: 'Estado',
                data: this.api.getStatusOperations()
            },
            {
                name: 'Pais',
                key: 'contry_cod',
                type: 'select',
                placeHolder: 'País',
                data: dataSelectCountry
            },
            {
                name: 'Buscar',
                key: 'button',
                type: 'button'
            },

        ];

    }

    changePage(page) {
        this.allCashinOrdersData = this.allCashinOrders(
            this.cookie.get('merchant'), {...this.currentFilters, page}
        );
    }

    changePageSize(size) {
        this.pageSize = size;
        this.allCashinOrdersData = this.allCashinOrders(
            this.cookie.get('merchant'), {...this.currentFilters, per_page: this.pageSize}
        );
    }

    changeDataTable(data) {
        this.from_date = data.period ? moment(data.period[0]).format('YYYY-MM-DD') : '';
        this.to_date = data.period ? moment(data.period[1]).format('YYYY-MM-DD') : '';

        this.countryCod = data.contry_cod ? data.contry_cod : null;

        if (data.type && data.type === 'search') {
            this.allCashinOrdersData = this.allCashinOrders(
                this.cookie.get('merchant'), {
                    ...this.currentFilters,
                    from_date: this.from_date,
                    to_date: this.to_date,
                    find_by_filters: data.data
                }
            );
        } else {
            this.currentFilters = {...data, from_date: this.from_date, to_date: this.to_date};
            this.allCashinOrdersData = this.allCashinOrders(
                this.cookie.get('merchant'), {...data, from_date: this.from_date, to_date: this.to_date}
            );
        }
    }


    search(array, word) {

        word = word.toLowerCase();

        const res = [];
        let headers = [];

        if (array.headers) {

            headers = array.headers;
            let index = 0;
            let itemsProcessed = 0;
            array.data.forEach(element => {

                for (const k in element) {
                    if (element.hasOwnProperty(k)) {
                        if (element[k].toString().toLowerCase().indexOf(word) > -1) {
                            res.push(array.data[index]);
                        } else {
                            if (element[k] && typeof (element[k]) == 'object') {
                                for (const x in element[k]) {
                                    for (const i in element[k][x]) {
                                        if (element[k][x][i] && element[k][x][i].toString().toLowerCase().indexOf(word) > -1) {
                                            res.push(array.data[index]);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                itemsProcessed++;
                if (itemsProcessed == array.data.length) {
                    this.allCashinOrdersData = {
                        data: res,
                        headers,
                        source: 'cashout-operations'
                    };
                }

                index++;
            });

        } else {

            array.then((e: any) => {

                headers = e.headers;
                let index = 0;
                let itemsProcessed = 0;
                e.data.forEach(element => {
                    for (const k in element) {
                        if (element.hasOwnProperty(k)) {


                            if (element[k] && element[k].toString().toLowerCase().indexOf(word) > -1) {
                                res.push(e.data[index]);
                            } else {
                                if (element[k] && typeof (element[k]) == 'object') {
                                    for (const x in element[k]) {
                                        for (const i in element[k][x]) {
                                            if (element[k][x][i] && element[k][x][i].toString().toLowerCase().indexOf(word) > -1) {
                                                res.push(e.data[index]);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    itemsProcessed++;
                    if (itemsProcessed == e.data.length) {
                        this.allCashinOrdersData = {
                            data: res,
                            headers,
                            source: 'cashout-operations'
                        };
                    }

                    index++;
                });
            });
        }

    }

    export() {

        const data = {
            token: JSON.parse(this.cookie.get('ud')).token,
            merchant: this.merchant,
            service: 'cashout/transactions/download/',
            data: {
                from_date: this.from_date,
                to_date: this.to_date,
                status: $('#status').val(),
                language: this.lang,
                contry_cod: this.countryCod
            }
        };

        this.api.api(data);
        $('#charging').addClass('hide');
    }

}
