import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import * as XLSX from 'xlsx';
import { CustomerService } from '../../../services/customers.service';
import { UtilitiesService } from '../../../services/utilities.service';
import { ApiService } from './../../../services/api.service';



type AOA = any[][];

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss']
})

export class ImportComponent implements OnInit {

  @Input() title: any;
  importType = '';
  titlePage = '';
  step = 1;
  data: AOA = [];
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  fileName = 'SheetJS.xlsx';
  headers: any = [];
  body: any = [];
  file: any;
  formData: FormData = new FormData();
  titleFile: String =  'Drag and drop your file';
  public merchant: any = JSON.parse(this.cookie.get('ud')).MerchantActive[0].MerchantData.MerchantCode;
  confirmation: any = null;
  Process = '';
  Error = '';
  isFinalStep = false;

  constructor(
    private customerService: CustomerService, 
    private api: ApiService, 
    private cookie: CookieService,
    private utilsService: UtilitiesService,
    public router: Router, 
    private modalService: NzModalService ) { }

  ngOnInit() {
    this.formatInputData();
    this.changeMerchant();
    this.api.validateMerchantPermissions();
  }

  formatInputData() {

    if (this.title === 'cashout') {
      this.titlePage = 'Cash Out > Operaciones > Importar';
      this.importType = 'cashout/payroll';
    }
    if (this.title === 'cashin') {
      this.titlePage = 'cashin > orders > Importar';
      this.importType = 'cashin/transactions/batchfile';

    }
    if ((this.title === 'customers')) {
      this.titlePage = 'customers > Importar';
      this.importType = 'customer/batchfile';
    }
  }

  changeMerchant() {
    this.api.merchant$.subscribe(m => {
      if (m.event === 'change-merchant') {
        this.merchant = m.data;
        this.api.validateMerchantPermissions();
      }

    });
  }

  onFileChange(evt: any) {

    this.file = evt.dataTransfer ? evt.dataTransfer.files[0] : evt.target.files[0];
    this.data = [];
    this.headers = [];
    this.body = [];
    this.step = 2;

    this.parseXlsx(this.file);

  }

  parseXlsx(file: File): void {
		const reader = new FileReader();
		reader.onload = (e: any) => {
			const data = new Uint8Array(e.target && e.target.result as ArrayBuffer);
			const workbook = XLSX.read(data, { type: 'array' });
			const firstSheetName = workbook.SheetNames[0];
			const worksheet = workbook.Sheets[firstSheetName];
			const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 }) as string[][];
			const filteredJson = json.filter((row) => row.length > 0);

      this.headers = filteredJson[0];
      this.body = filteredJson.slice(1);
		};
		reader.readAsArrayBuffer(file);
	}

  next() {
    const self = this;
    const file = new FormData();

    if (this.file) {
      file.append('file', this.file, this.file.name);
      file.append('metadata', JSON.stringify(JSON.parse(this.cookie.get('ud')).userDetails));
      file.append('merchant_code', this.merchant);
    }

    if (this.step === 2) {
      if (this.importType === 'cashout/payroll' || this.importType === 'cashin/transactions/batchfile') {
        this.createBatchFile(this.file);
        return;
      } 
      

      const data = {
        token: JSON.parse(this.cookie.get('ud')).token,
        service: this.importType,
        data: file
      };

      this.api.api(data).toPromise().then((data: any) => {
        $('#charging').addClass('hide');

        this.Process = data.Process;
        this.Error = data.Error;

        if (this.importType = 'customer/batchfile') {
          this.Process = data.total_procces;
          this.Error = data.total_error;
        }


        if (data.Error > 0) {
          this.confirmation = false;
          console.log('error');

        } else {
          console.log('Sin error');
          this.isFinalStep = true;
          this.confirmation = true;
        }
      }).catch(error => {
        $('#charging').addClass('hide');
        if (error.status === 401) {
          this.cookie.set('ud', '');
          this.router.navigate(['']);
        }
      });
    }

    if (this.isFinalStep) {
      if (this.importType = 'customer/batchfile') {
        this.router.navigate(['/customers']);
      } 
    }
  }

  createBatchFile(file: File) {
    this.utilsService.updateLoading(true)
		const metadata = JSON.stringify(JSON.parse(this.cookie.get('ud')).userDetails);
		const formData = new FormData();
		formData.append('file', file);
		formData.append('merchantCode', this.merchant.toString());
		formData.append('metadata', metadata);
		formData.append('type', this.importType === 'cashout/payroll' ? '2' : '1');

		this.customerService.createBatchFile(formData).subscribe((resp) => {
			if (resp.status) {
        this.utilsService.updateLoading(false)
				this.modalService.success({
          nzTitle: 'Archivo importado',
          nzContent: 'El archivo ha sido importado correctamente'
        });
        this.router.navigate(['/batch-files']);
			}
		}, ()=> {
      this.utilsService.updateLoading(false)
    });
	}
}
