import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpRequest } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { NzModalService } from 'ng-zorro-antd/modal';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

interface OptionsUrl {
  type?: 'merchant' | 'admin';
  params?: any;
}

export interface ApiError {
	message: string;
	statusCode: number;
	status: boolean;
	title: string;
	type: string;
}

const API = environment.api;

const isAccessHeaders = (req: HttpRequest<unknown>): boolean => {
	const services = ['api-core-access.wepay4u.com', 'api-client-merchant.wepay4u.com'];
	const hasSelfToken = services.some((service) => req.url.includes(service));
	return hasSelfToken;
};

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  urlMerchant = API.urlMerchant;
  urlAdmin = API.urlAdmin;
  urlClientMerchant = API.urlClientMerchant;
  url
  blackList = ['balance', 'cashout/transactions/WithParams', 'cashin/transactions/WithParams'];

  merchant$ = new EventEmitter<any>();
  constructor(private http: HttpClient, private cookie: CookieService, public router: Router, private modalService: NzModalService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (isAccessHeaders(req) && localStorage.getItem('authAccess')) {
      const tokenAuthAccess = JSON.parse(localStorage.getItem('authAccess')).data.access_token;
      req = req.clone({
        setHeaders: {
            Authorization: `Bearer ${tokenAuthAccess}`
        }
      })
      return next.handle(req).pipe(
        catchError((error: any) => {
          this.handleError(error);
          throw new Error(('Ups algo salió mal'));
        })
    );
    }

    if (req.url.indexOf('auth') === -1) {
      let idToken = '';
      if (this.cookie.get('ud') && this.cookie.get('ud') !== '') {
        idToken = JSON.parse(this.cookie.get('ud')).token;
      }

      if (idToken) {
        const cloned = req.clone({
          headers: req.headers
            .set('Authorization', 'Bearer ' + idToken)
        });
        return next.handle(cloned).pipe(
            catchError((error: any) => {
              this.handleError(error);
              throw new Error(error);
            })
        );
      } else {
        this.router.navigate(['/login']);
        return next.handle(req).pipe(
            catchError((error: any) => {
              this.handleError(error);
              throw new Error(error);
            })
        );
      }

    } else {      
      return next.handle(req).pipe(
        catchError((error: any) => {
          this.handleError(error);
          throw new Error(error);
        })
    );
    }
  }



  api(datos) {
    if (!this.blackList.includes(datos.service)) {
      $('#charging').removeClass('hide');
    }

    let header = {
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${datos.token}`)
        .set('Content-Type', 'application/json')
    };

    const headerDownload = {
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${datos.token}`)
        .set('Content-Type', 'application/json')
        .set('responseType', 'blob')
    };

    const headerFile = {
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${datos.token}`)
        .set('Content-Type', 'multipart/form-data')
        .set('Accept', 'application/json')
    };


    const headerUpload = {
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${datos.token}`)
        .set('Content-Type', null)
        .set('Accept', 'multipart/form-data')
    };

    switch (datos.service) {

      case 'cashin/transactions/':
        return this.http.get(`${this.urlMerchant + datos.service + datos.merchant + this.param(datos.data)}`, header);
      case 'cashin/transactions/customer':
        return this.http.get(`${this.urlMerchant + 'cashin/transactions/' + datos.merchant + this.param(datos.data)}`, header);
      case 'cashin/transactions/post':
        return this.http.post(`${this.urlMerchant + 'cashin/transactions'}`, datos.data);
        case 'cashin/refund_order':
        return this.http.post(`${this.urlMerchant + 'cashin/refund_order'}`, datos.data);
      case 'cashin/transactions/WithParams':
        return this.http.get(`${this.urlMerchant + 'cashin/transactions/' + datos.merchant + this.param(datos.data)}`, header);
      case 'cashin/transactions/download/':
        window.location.href = this.urlMerchant + 'cashin/transactions/download/' + datos.merchant + this.param(datos.data);
        break;
      case 'cashin/transactions/confirm_recharge':
          return this.http.post(`${this.urlMerchant + datos.service}`, datos.data);
      case 'conciliation/':
        return this.http.get(`${this.urlMerchant + datos.service + datos.merchant + this.param(datos.data) }`);
      case 'conciliation/requestmoney':
        return this.http.post(`${this.urlMerchant + datos.service}`, datos.data);
      case 'settlement/':
        return this.http.get(`${this.urlMerchant + datos.service + datos.merchant}`, header);
      case 'settlement/WithParams':
        return this.http.get(`${this.urlMerchant + 'settlement/' + datos.merchant + this.param(datos.data)}`, header);
      case 'settlement/download/':
        window.location.href = this.urlMerchant + 'settlement/download/' + datos.merchant + this.param(datos.data);
        break;
      case 'settlement/detail/':
        return this.http.get(`${this.urlMerchant + datos.service + datos.merchant + '/' + datos.data}`, header);
      case 'cashin/transactions/detail/':
        if (datos.data.PublicId) {
          return this.http.get(`${this.urlMerchant + 'cashin/transactions/detail/' + datos.merchant + '/' + datos.data.PublicId}`, header);
        }
        return this.http.get(`${this.urlMerchant + datos.service + datos.merchant + '/' + datos.data}`, header);
      case 'cashout/transactions/':
        return this.http.get(`${this.urlMerchant + datos.service + datos.merchant + this.param(datos.data)}`, header);
        case 'cashout/transactions/customer':
          return this.http.get(`${this.urlMerchant + 'cashout/transactions/' + datos.merchant + this.param(datos.data)}`, header);
      case 'cashout/transactions/WithParams':
        return this.http.get(`${this.urlMerchant + 'cashout/transactions/' + datos.merchant + this.param(datos.data)}`, header);
      case 'cashout/transactions/detail/':
        return this.http.get(`${this.urlMerchant + datos.service + datos.merchant + '/' + datos.data}`, header);
      case 'cashout/transactions/batchfile':
        return this.http.post(`${this.urlMerchant + datos.service}`, datos.data);
      case 'cashin/transactions/batchfile':
        return this.http.post(`${this.urlMerchant + datos.service}`, datos.data);
      case 'cashout/transactions/download/':
        window.location.href = this.urlMerchant + 'cashout/transactions/download/' + datos.merchant + this.param(datos.data);
        break;
      case 'dasboard/cashin/':
      return this.http.get(`${this.urlMerchant + datos.service + datos.merchant + this.param(datos.data)}`, header);
      case 'dasboard/cashout/':
        return this.http.get(`${this.urlMerchant + datos.service + datos.merchant + this.param(datos.data)}`, header);
      case 'bankAccount/':
        return this.http.get(`${this.urlMerchant + datos.service + datos.merchant}`, header);
      case 'cashout/payroll':
        return this.http.post(`${this.urlMerchant + datos.service}`, datos.data);
      case 'cashout/payroll/':
        return this.http.get(`${this.urlMerchant + datos.service + datos.merchant}`, header);
      case 'cashout/payroll/WithParams':
        return this.http.get(`${this.urlMerchant + 'cashout/payroll/' + datos.merchant + this.param(datos.data)}`, header);
      case 'cashout/payroll/put':
        return this.http.put(`${this.urlMerchant + 'cashout/payroll/' + datos.merchant + '/' +  datos.id }`, datos.data);
      case 'cashout/payroll/detail/':
        return this.http.get(`${this.urlMerchant + 'cashout/payroll/' + datos.merchant + '/' + datos.data}`);


      case 'customer/':
        return this.http.get(`${this.urlMerchant + datos.service + datos.merchant + this.param(datos.data)}`, header);
      case 'customer/create/':
        return this.http.post(`${this.urlMerchant + 'customer'}`, datos.data);
      case 'customer/download/':
        window.location.href = this.urlMerchant + 'customer/download/' + datos.merchant + this.param(datos.data);
        break;
      case 'customer/batchfile':
        return this.http.post(`${this.urlMerchant + datos.service}`, datos.data);

      case 'batchfiles/list':
        return this.http.get(`${this.urlClientMerchant + datos.service + this.param(datos.data)}`);


      case 'listAllOperationCashin':
        return this.http.get(`${this.urlMerchant + datos.service}`, header);
      case 'listAllOperationCashOut':
        return this.http.get(`${this.urlMerchant + datos.service}`, header);
      case 'auth/login':
        return this.http.post(`${this.urlMerchant + datos.service}`, datos);
      case 'auth/logout':
        return this.http.get(`${this.urlMerchant + datos.service}`, header);
      case 'listMerchant':
        return this.http.get(`${this.urlMerchant + datos.service}`, header);
      case 'listAllOperationCashinWithParams':
        return this.http.get(`${this.urlMerchant + 'listAllOperationCashin' + this.param(datos.data)}`, header);
      case 'listAllOperationCashOutWithParams':
        return this.http.get(`${this.urlMerchant + 'listAllOperationCashOut' + this.param(datos.data)}`, header);
      case 'listBanksCashout':
        return this.http.get(`${this.urlMerchant + datos.service}`, header);
      case 'operationDetail':
        return this.http.get(`${this.urlMerchant + datos.service + '/' + datos.data}`, header);
      case 'loperationDetailCashout':
        return this.http.get(`${this.urlMerchant + datos.service + '/' + datos.data}`, header);
      case 'forcePayment/listOperation':
        return this.http.get(`${this.urlMerchant + datos.service}`, header);
      case 'forcePayment/listOperationWithParams':
        return this.http.get(`${this.urlMerchant + 'forcePayment/listOperation' + this.param(datos.data)}`, header);
      case 'listBanksCashin':
        return this.http.get(`${this.urlMerchant + datos.service}`, header);
      case 'forcePayment':
        return this.http.post(`${this.urlMerchant + datos.service}`, datos.data, header);
      case 'notification/listOperation':
        return this.http.get(`${this.urlMerchant + datos.service}`, header);
      case 'notification/listOperationWithParams':
        return this.http.get(`${this.urlMerchant + 'notification/listOperation' + this.param(datos.data)}`, header);
      case 'settlement/list':
        return this.http.get(`${this.urlMerchant + datos.service}`, header);
      case 'settlement/listWithParams':
        return this.http.get(`${this.urlMerchant + 'settlement/list' + this.param(datos.data)}`, header);
      case 'balance/listBalance':
        return this.http.get(`${this.urlMerchant + datos.service}`, header);
      case 'balance/listBalanceWithParams':
        return this.http.get(`${this.urlMerchant + 'balance/listBalance' + this.param(datos.data)}`, header);
      case 'notification/execute/':
        return this.http.get(`${this.urlMerchant + datos.service + datos.data}`, header);

      case 'balance/create':
        return this.http.post(`${this.urlMerchant + datos.service}`, datos.data);
      case 'payroll/listBanks':
        return this.http.get(`${this.urlMerchant + datos.service}`, header);
      case 'payroll/create':
        return this.http.get(`${this.urlMerchant + datos.service}`, header);
      case 'listAllOperationCashin/download':
        window.location.href = this.urlMerchant + 'listAllOperationCashin/download' + this.param(datos.data);
        break;
      case 'settlement/download':
        window.location.href = this.urlMerchant + 'settlement/download' + this.param(datos.data);
        break;
      case 'listAllOperationCashOut/download':
        window.location.href = this.urlMerchant + 'listAllOperationCashOut/download' + this.param(datos.data);
        break;
      case 'configuration/user/change_password':
        return this.http.post(`${this.urlMerchant + datos.service}`, datos.data);
      case 'balancebyBanck':
        return this.http.get(`${this.urlMerchant + 'balancebybank/' + datos.data}`, header);
      case 'countries':
        const urlCountry = `${API.urlAdmin}countries/1`;
        return this.http.get(urlCountry);
      default:

        if (datos.service.indexOf('balance') > -1) {
          return this.http.get(`${this.urlMerchant + datos.service}`, header);
        }

        if (datos.service.indexOf('configuration/user') > -1) {
          switch (datos.type) {
            case 'get-users':
              return this.http.get(`${this.urlMerchant + datos.service + datos.merchant}`, header);
            case 'get-users-with-params':
              return this.http.get(`${this.urlMerchant + 'configuration/user/' + datos.merchant + this.param(datos.data)}`, header);
            case 'post':
              return this.http.post(`${this.urlMerchant + datos.service}`, datos.data);
            case 'get-profile':
              return this.http.get(`${this.urlMerchant + datos.service}`, header);
            default:
            break;
          }
        }


        if (datos.service.indexOf('configuration/secretkey/') > -1) {
          if (datos.data.type == 'update') {
            return this.http.put(`${this.urlMerchant + datos.service}`, datos.data.data);
          } else {
            return this.http.get(`${this.urlMerchant + datos.service}`, header);
          }

        }

        if (datos.service.indexOf('configuration/webhook/') > -1) {

          if (datos.data.type == 'update') {
            return this.http.put(`${this.urlMerchant + datos.service}`, datos.data);
          } else {
            return this.http.get(`${this.urlMerchant + datos.service}`, header);
          }

        }

        break;

    }

  }

  param(object) {
    if (object) {
      const parameters = [];
      for (const property in object) {
        if (object.hasOwnProperty(property)) {
          if (object[property] && property != 'period') {
            parameters.push(encodeURI(property + '=' + (object[property] ? object[property] : '')));
          }
        }
      }
      return '?' + parameters.join('&');
    }
    return '';
  }


  queryGet<T>(route: string, options: OptionsUrl = {params: null}): Observable<T> {
    const urlBase = options.type === 'admin' ? this.urlAdmin : this.urlMerchant;
    return this.http.get<T>(urlBase.concat(route) + this.param(options.params))
        .pipe(
            catchError((error: any) => {
              this.handleError(error);
              throw new Error(('ups algo salió mal'));
            })
        );
  }

  queryDownload(route: string, params = null) {
    window.location.href = this.urlMerchant.concat(route) + this.param(params);
  }

  queryPost(route: string, body: any) {
    return this.http.post(this.urlMerchant.concat(route), body)
        .pipe(
            catchError((error: any) => {
              this.handleError(error);
              throw(error);
            })
        );
  }

  queryPut(route: string, body: any) {
    return this.http.put(this.urlMerchant.concat(route), body)
        .pipe(
            catchError((error: any) => {
              this.handleError(error);
              throw(error);
            })
        );
  }

  private handleError(error: HttpErrorResponse): void {
    let message = '';
    let logout = false;
    let apiError: ApiError | null = null;

    if (error.url.includes('api-core-access.wepay4u.com')) {
      apiError = error.error as ApiError;
    }

    if (error.status == 404) {
      message = 'El servidor no pudo procesar la solicitud!';
      logout = false;
    } else if (error.status === 500) {
      // this.router.navigate(['/error-500']);
    } else if (error.status === 422 || error.status === 400) {
      const result = error;    

      if (result.error) {
        const error = result.error;
        if (Array.isArray(error)) {
          error.forEach((err) => {
            message += err + '<br>';
          });
        } else {
          for (const key of Object.keys(error)) {
            const errors = error[key];
            if (Array.isArray(errors) == true) {
              errors.forEach((err) => {
                message += err + '<br>';
              });
            }
          }
        }
      } else {
        message = result.message;
      }
    } else if (error.status == 401 || error.status == 403) {
      console.log('error', error);
      
      logout = true;
      message = error.error.message;
    } else if (error.status == 503) {
      message = 'Algo no va bien, parece que no tiene conexión!';
      logout = true;
    }

    if (message || apiError) {    
      this.modalService.error({
        nzTitle: 'Error',
        nzContent: apiError ? apiError.message : message
      });
    }
    if (logout) {
      this.logout();
    }
  }

  logout(): void {
    this.queryGet('auth/logout').subscribe(
        () => {
          this.cookie.deleteAll();
          this.router.navigate(['/login']);
        },
        err => {
          this.cookie.deleteAll();
          this.router.navigate(['/login']);
        }
    );
  }



  getCustomerType() {
    return [
      {
        name: 'Shopper',
        value: '1',
      },
      {
        name: 'Agente',
        value: '2',
      }
    ];

  }

  getStatus() {
    return [
      {
        name: 'Expirado',
        value: 'o',
      },
      {
        name: 'Pendiente',
        value: '10',
      },
      {
        name: 'Anulado',
        value: '11',
      },
      {
        name: 'Pagado',
        value: '12',
      },
      {
        name: 'Notificado',
        value: '14',
      },
      {
        name: 'Liquidado',
        value: '16',
      },
      {
        name: 'Conciliado',
        value: '13',
      },
      {
        name: 'Error Notificación',
        value: '15',
      }
    ];
  }

  getStatusOperations() {
    return [
      {
        name: 'Anulado por comercio',
        value: 'o',
      },
      {
        name: 'Creado, pendiente aprobación',
        value: '10',
      },
      {
        name: 'Aprobado por comercio',
        value: '11',
      },
      {
        name: 'En proceso',
        value: '12',
      },
      {
        name: 'Rechazado por banco',
        value: '13',
      },
      {
        name: 'Procesado',
        value: '14',
      },
      {
        name: 'Notificado',
        value: '15',
      },
      {
        name: 'Error al notificar',
        value: '16',
      },
      {
        name: 'Reprocesar',
        value: '17',
      },
      {
        name: 'Extornado',
        value: '18',
      }
    ];
  }

  getStatusCashOutOperations() {
    return [
      {
        name: 'Anulado',
        value: '0',
      },
      {
        name: 'Creado',
        value: '10',
      },
      {
        name: 'Aprobación del cliente',
        value: '11',
      },
      {
        name: 'En proceso (Payroll creado)',
        value: '12',
      },
      {
        name: 'Rechazado por el banco',
        value: '13',
      },
      {
        name: 'Trasferencia completa',
        value: '14',
      },
      {
        name: 'Notificado',
        value: '15',
      },
      {
        name: 'Error en notificar',
        value: '16',
      },
      {
        name: 'Reprocesar',
        value: '17',
      }
    ];
  }

  getStatusCashInSttelement() {
    return [
      {
        name: 'Anulado',
        value: '0'
      },
      {
        name: 'Creado',
        value: '1'
      },
      {
        name: 'Pagado',
        value: '2'
      },
      {
        name: 'Notificada',
        value: '3'
      },
    ];
  }


  getStatusActive() {
    return [
      {
        name: 'Activo',
        value: '1'
      },
      {
        name: 'Inactivo',
        value: '2'
      }
    ];
  }


  getStatusCashOut() {
    return [
      {
        name: 'Anulado',
        value: 'o',
      },
      {
        name: 'Creado',
        value: '10',
      },
      {
        name: 'Aprobación de cliente',
        value: '11',
      },
      {
        name: 'En proceso (Payroll)',
        value: '12',
      },
      {
        name: 'Error en procesar',
        value: '13',
      },
      {
        name: 'Procesado',
        value: '14',
      },
      {
        name: 'Notificado',
        value: '15',
      },
      {
        name: 'Error al notificar',
        value: '16',
      },
      {
        name: 'Reprocesar',
        value: '17',
      }
    ];
  }

  getStatusPayroll() {
    return [
      {
        name: 'Creado',
        value: '1',
      },
      {
        name: 'Aprobado',
        value: '2',
      },
      {
        name: 'Procesado',
        value: '3',
      }
    ];
  }

  getStatusByIDPayroll(id) {

    id = id.toString();

    switch (id) {
      case '1':
        return 'Creado';
      case '2':
        return 'Aprobado';
      case '3':
        return 'Procesado';
        default:
          return id;
    }

  }


  getStatusByID(id) {

    id = id.toString();

    switch (id) {
      case 'o':
        return 'Expirado';
      case '10':
        return 'Pendiente';
      case '11':
        return 'Anulado';
      case '12':
        return 'Pagado';
      case '14':
        return 'Notificado';
      case '16':
        return 'Liquidado';
      case '13':
        return 'Conciliado';
      case '15':
        return 'Error Notificación';
      case '18':
          return 'Trasferencia Completa';
      default:
        return 'Expirado';
    }

  }

  getStatusByIDCashInSttelement(id) {
    id = id.toString();
    switch (id) {
      case '0':
        return 'Anulado';
      case '1':
        return 'Creado';
      case '2':
        return 'Pagado';
      case '3':
        return 'Notificado';
      default:
        return 'Desconocido';
    }
  }

  getStatusByIDCashoutOperations(id) {
    id = id.toString();
    switch (id) {
      case 'o':
        return 'Anulado';
      case '0':
        return 'Anulado';
      case '10':
        return 'Creado';
      case '11':
        return 'Aprobación de cliente';
      case '12':
        return 'En proceso (Payroll creado)';
      case '13':
        return 'Rechazado por el banco';
      case '14':
        return 'Trasferencia completa';
      case '15':
        return 'Notificado';
      case '16':
        return 'Error en notificar';
      case '17':
        return 'Reprocesar';
      case '18':
        return 'Extornado';
      default:
        return 'Desconocido';
    }
  }


  getStatusByIDCashOut(id) {

    id = id.toString();

    switch (id) {
      case 'o':
        return 'Anulado';
      case '10':
        return 'Creado';
      case '11':
        return 'Aprobación de cliente';
      case '12':
        return 'En proceso (Payroll)';
      case '13':
        return 'Rechazado por el banco';
      case '14':
        return 'Trasferencia completa';
      case '15':
        return 'Notificado';
      case '16':
        return 'Error al notificar';
      case '17':
        return 'Reprocesar';
      default:
      return 'Expirado';
    }
  }

  validateMerchantPermissions() {
    if (this.cookie.get('ud') !== '') {
      const merchantList = JSON.parse(this.cookie.get('ud')).MerchantActive;
      const selectedMerchantCode = JSON.parse(this.cookie.get('merchant'));
      let selectedItem;

      merchantList.forEach(item => {
        if (item.MerchantData.MerchantCode === selectedMerchantCode) {
          selectedItem = item;
        }
      });

      if (selectedItem) {
        (selectedItem.MerchantData.ActiveCashin) ? $('#navCashin').show() : $('#navCashin').hide();
        (selectedItem.MerchantData.ActiveCashout) ? $('#navCashout').show() : $('#navCashout').hide();
      }
    }
  }


}
