import { Component, Input } from '@angular/core';
import { Refund2 } from '../../../interfaces/IRefund2';

@Component({
  selector: 'app-refund-detail',
  templateUrl: './refund-detail.component.html',
  styleUrls: ['./refund-detail.component.scss']
})
export class RefundDetailComponent {
  @Input() refunds: Refund2[];
  protected status = {
    1 : {name: 'Creado', color: 'green'},
    2 : {name: 'Procesado', color: 'blue'},
    3 : {name: 'Rechazado', color: 'red'}
  };


  constructor() { }

  getStatus(id): any {
    return this.status[id] || {name: 'Desconocido', color: ''};
  }
}
