import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as $ from 'jquery';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent implements OnInit {

  lang: any = this.translate.getDefaultLang();

  public allCustomersData: any = this.allCustomers(this.cookie.get('merchant'));
  public allCustomersDataTmp: any = this.allCustomers(this.cookie.get('merchant'));
  public titlePage = 'Clientes';
  public filters: any = this.getFilters();

  isDetailExportVisible = false;
  isNewCustomerModalOpen = false;
  formCustomer: FormGroup;
  isInstruccionesVisible = false;
  creationResponse = 'test';
  showCompletedFieldErrorMessage = false;


  from_date: any = moment('2019').startOf('month').add(1, 'days').format('YYYY-MM-DD');
  from_date_cashout: any = moment().startOf('month').add(1, 'days').format('YYYY-MM-DD');
  to_date: any = moment().add(1, 'days').format('YYYY-MM-DD');
  to_date_cashout: any = moment().add(1, 'days').format('YYYY-MM-DD');

  public userDetails = JSON.parse(this.cookie.get('ud')).userDetails;
  public MerchantActive = JSON.parse(this.cookie.get('ud')).MerchantActive;
  public merchant: any = JSON.parse(this.cookie.get('ud')).MerchantActive[0].MerchantData.MerchantCode;

  constructor(private api: ApiService, private cookie: CookieService, private router: Router, public translate: TranslateService) {
    this.formCustomer =   this.createFormGroup();
  }

  ngOnInit() {
    this.changeMerchant();
    this.api.validateMerchantPermissions();

  }
  // crea formulario para creación de cliente
  createFormGroup() {
    return new FormGroup({
      FirstName: new FormControl('', [Validators.required]),
      LastName: new FormControl('', [Validators.required]),
      TypeDoc: new FormControl('', [Validators.required]),
      DocNumber: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(3)]),
      Email: new FormControl(''),
      Mobile: new FormControl('', [Validators.pattern('^[0-9]*$')]),
      Address: new FormControl(''),
      CustomerType: new FormControl('', [Validators.required]),
      CountryCode: new FormControl('', [Validators.required]),
    });
  }


  // Ejecuta cambios en la vista cuando se cambia de merchant.
  changeMerchant() {
    this.api.merchant$.subscribe(m => {
      if (m.event === 'change-merchant') {
        this.allCustomersData = this.allCustomers(m.data);
        this.allCustomersDataTmp = this.allCustomers(m.data);
        this.merchant = m.data;
        this.api.validateMerchantPermissions();
      }
    });
  }

  // Ejecuta el servicio para sonculta de todos los clientes
  async allCustomers(merchant, dataInfo: any = false) {
    const data = {
      token: JSON.parse(this.cookie.get('ud')).token,
      merchant,
      service: 'customer/',
      data: {
        from_date: moment('2019').startOf('month').format('YYYY-MM-DD'),
        to_date: moment(this.to_date).format('YYYY-MM-DD')
      }
    };

    const headers = [
      {
        name: 'Nombre',
        key: 'FullName',
        opc: true
      },
      {
        name: 'T. Documento',
        key: 'DocType',
        opc: true
      },
      {
        name: 'Documento',
        key: 'DocNumber',
        opc: true
      },
      {
        name: 'T. Cliente',
        key: 'CustomerType',
        opc: true
      },
      {
        name: 'Fecha de creación',
        key: 'created_at',
        opc: true
      },
      {
        name: 'Opciones',
        key: 'opc',
        opc: true,
        functions: ['in', 'out', 'link']
      }

    ];

    return {
      headers,
      data: await this.api.api(data).toPromise(),
      source: 'listAllCustomer'
    };
  }

  // creo que no esta funcionando aún.
  // Se ejecut cad vez que cambia la tabla que oista los clientes
  changeDataTable(data) {
    if (data.type && data.type === 'search') {

      if (data.data === '') {
        this.allCustomersData = this.allCustomersDataTmp;
        this.allCustomersDataTmp = this.allCustomersData;
      } else {
        this.allCustomersData = [];
        this.search(this.allCustomersDataTmp, data.data);
      }
    } else {
      this.allCustomersData = this.allCustomers(this.cookie.get('merchant'), data);
      this.allCustomersDataTmp = this.allCustomers(this.cookie.get('merchant'), data);
    }
  }

  // Metodo para hacer una busqueda en listado de clietnes-
  search(array, word) {

    word = word.toLowerCase();

    const res = [];
    let headers = [];

    if (array.headers) {

      headers = array.headers;
      let index = 0;
      let itemsProcessed = 0;
      array.data.forEach(element => {

        for (const k in element) {
          if (element.hasOwnProperty(k)) {
            if (element[k].toString().toLowerCase().indexOf(word) > -1) {
              res.push(array.data[index]);
            } else {
              if (element[k] && typeof (element[k]) == 'object') {
                for (const x in element[k]) {
                  for (const i in element[k][x]) {
                    if (element[k][x][i] && element[k][x][i].toString().toLowerCase().indexOf(word) > -1) {
                      res.push(array.data[index]);
                    }
                  }
                }
              }
            }
          }
        }

        itemsProcessed++;
        if (itemsProcessed == array.data.length) {
          this.allCustomersData = {
            data: res,
            headers,
            source: 'listAllCustomer'
          };
        }

        index++;
      });

    } else {

      array.then((e: any) => {

        headers = e.headers;
        let index = 0;
        let itemsProcessed = 0;
        e.data.forEach(element => {
          for (const k in element) {
            if (element.hasOwnProperty(k)) {


              if (element[k] && element[k].toString().toLowerCase().indexOf(word) > -1) {
                res.push(e.data[index]);
              } else {
                if (element[k] && typeof (element[k]) == 'object') {
                  for (const x in element[k]) {
                    for (const i in element[k][x]) {
                      if (element[k][x][i] && element[k][x][i].toString().toLowerCase().indexOf(word) > -1) {
                        res.push(e.data[index]);
                      }
                    }
                  }
                }
              }
            }
          }

          itemsProcessed++;
          if (itemsProcessed == e.data.length) {
            this.allCustomersData = {
              data: res,
              headers,
              source: 'listAllCustomer'
            };
          }

          index++;
        });
      });
    }

  }

  // Metodo aun no esta funcionando
  // Exporta el listado de clietnes en Excel.
  export() {
    const data = {
      token: JSON.parse(this.cookie.get('ud')).token,
      merchant: this.merchant,
      service: 'customer/download/',
      data: {
        from_date: $('#f_start').val(),
        to_date: $('#f_end').val(),
        language: this.lang
      }
    };


    this.api.api(data);
    $('#charging').addClass('hide');
    this.toggleDetailExport();
  }

  // Muesta submenu de opciones para exportar
  toggleDetailExport() {
    this.isDetailExportVisible = !this.isDetailExportVisible;
  }

  // No esta funcionando
  // Muestra ventana modal del formulario para crear un cliente.
  openCreateCustomer() {
    this.isNewCustomerModalOpen = !this.isNewCustomerModalOpen;
  }

  // Refresca los valores de los clientes.
  refreshValues() {
    this.allCustomersData = this.allCustomers(this.cookie.get('merchant'));
    this.allCustomersDataTmp = this.allCustomers(this.cookie.get('merchant'));
  }

  // Retorna los tipos de filtros usados para esta vista
  async getFilters() {

    return [
      {
        name: 'Periodo',
        key: ['from_date', 'to_date'],
        type: 'period'
      },
      {
        name: 'Estado',
        key: 'status',
        type: 'select',
        data: this.api.getCustomerType()
      },
      {
        name: 'Buscar',
        key: 'button',
        type: 'button'
      },

    ];

  }


  // Se ejecuta al precionar boton de cancelar.
  handleCancel() {
    this.isInstruccionesVisible = false;
    this.formCustomer =   this.createFormGroup();
    this.isNewCustomerModalOpen = !this.isNewCustomerModalOpen;
    this.showCompletedFieldErrorMessage = false;
    this.refreshValues();
  }

  // Manejo de errores del formulario
  handleErrors() {

    if (this.formCustomer.controls.FirstName.status === 'INVALID') {
      this.formCustomer.controls.FirstName.markAsDirty();
      this.formCustomer.controls.FirstName.markAllAsTouched();
    }

    if (this.formCustomer.controls.LastName.status === 'INVALID') {
      this.formCustomer.controls.LastName.markAsDirty();
      this.formCustomer.controls.LastName.markAllAsTouched();
    }

    if (this.formCustomer.controls.TypeDoc.status === 'INVALID') {
      this.formCustomer.controls.TypeDoc.markAsDirty();
      this.formCustomer.controls.TypeDoc.markAllAsTouched();
    }

    if (this.formCustomer.controls.DocNumber.status === 'INVALID') {
      this.formCustomer.controls.DocNumber.markAsDirty();
      this.formCustomer.controls.DocNumber.markAllAsTouched();
    }

    if (this.formCustomer.controls.Email.status === 'INVALID') {
      this.formCustomer.controls.Email.markAsDirty();
      this.formCustomer.controls.Email.markAllAsTouched();
    }

    if (this.formCustomer.controls.Mobile.status === 'INVALID') {
      this.formCustomer.controls.Mobile.markAsDirty();
      this.formCustomer.controls.Mobile.markAllAsTouched();
    }

    if (this.formCustomer.controls.Address.status === 'INVALID') {
      this.formCustomer.controls.Address.markAsDirty();
      this.formCustomer.controls.Address.markAllAsTouched();
    }

    if (this.formCustomer.controls.CustomerType.status === 'INVALID') {
      this.formCustomer.controls.CustomerType.markAsDirty();
      this.formCustomer.controls.CustomerType.markAllAsTouched();
    }

    if (this.formCustomer.controls.CountryCode.status === 'INVALID') {
      this.formCustomer.controls.CountryCode.markAsDirty();
      this.formCustomer.controls.CountryCode.markAllAsTouched();
    }
  }


  // manejo del formulario en OK
  handleOk() {

    console.log('Entro al handleOk');
    if (this.formCustomer.status === 'INVALID') {
      console.log('Entro invalid formulario');
      this.showCompletedFieldErrorMessage = true;
      this.handleErrors();
    } else {
      this.showCompletedFieldErrorMessage = false;
      console.log('Paso la validacion de formulario ok');
      const data = {
        token: JSON.parse(this.cookie.get('ud')).token,
        merchant: this.merchant,
        service: 'customer/create/',
        type: 'post',
        data: {
          MerchantCode: this.merchant,
          FirstName: this.FirstName.value,
          LastName: this.LastName.value,
          TypeDoc: this.TypeDoc.value,
          DocNumber: this.DocNumber.value,
          Email: this.Email.value,
          Mobile: this.Mobile.value,
          Address: this.Address.value,
          CustomerType: this.CustomerType.value,
          CountryCode: this.CountryCode.value,
          Metadata: JSON.stringify(JSON.parse(this.cookie.get('ud')).userDetails),
        }
      };

      console.log(data);

      if (!this.isInstruccionesVisible) {
          console.log('Entro a ejecutar el servicio de crear');

          this.api.api(data).toPromise().then((res: any) => {
            this.isInstruccionesVisible = true;
            this.creationResponse = res.UrlRedirect;
            this.formCustomer.reset();
            $('#charging').addClass('hide');
            this.refreshValues();
        })
        .catch(e => {
          console.log(e);
          $('#charging').addClass('hide');
          if (e.status === 401 || e.status === 0) {
            this.cookie.set('ud', '');
            this.router.navigate(['/']);
          }
        });

      } else {
        this.handleCancel();
        this.refreshValues();
        this.creationResponse = '';
      }
    }
  }


  get FirstName() { return this.formCustomer.get('FirstName'); }
  get LastName() { return this.formCustomer.get('LastName'); }
  get TypeDoc() { return this.formCustomer.get('TypeDoc'); }
  get DocNumber() { return this.formCustomer.get('DocNumber'); }
  get Email() { return this.formCustomer.get('Email'); }
  get Mobile() { return this.formCustomer.get('Mobile'); }
  get Address() { return this.formCustomer.get('Address'); }
  get CustomerType() { return this.formCustomer.get('CustomerType'); }
  get CountryCode() { return this.formCustomer.get('CountryCode'); }



}
