import { LocationStrategy } from '@angular/common';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MerchantActive } from '../../../interfaces/ResponseLogin';
import { ApiService } from '../../../services/api.service';
import { UtilitiesService } from './../../../services/utilities.service';

export enum MerchantType {
    VTEX_CUOTEALO = 'VTEX_CUOTEALO',
    LINKPAGO_CUOTEALO = 'LINKPAGO_CUOTEALO'
}

const pattern = '^[0-9]+(\.[0-9]{1,2})?$';

@Component({
    selector: 'app-new-order',
    templateUrl: './new-order.component.html',
    styleUrls: ['./new-order.component.scss']
})
export class NewOrderComponent implements OnInit, OnDestroy {
    @ViewChild('modalContent', {static: true}) modalContent: TemplateRef<unknown>;

    public destroy$ = new Subject();
    public newOrderForm: FormGroup;
    public selectedIndexTab = 0;
    public idOperation: string = null;
    public merchant: any = JSON.parse(this.cookie.get('merchant'));
    public merchantActive!: MerchantActive;
    public supportedCurrencies: any[] = [];
    public supportedCountries: any[] = [];
    public MerchantType = MerchantType
    public shortUrl = '';
    protected iconCopy = 'copy'
    protected msgErrors = [
        {error: 'required', message: 'Este campo es requerido'},
        {error: 'pattern', message: 'El formato no es válido'},
        {error: 'min', message: 'El monto mínimo es 70.00'},
    ]

    public channels: any[] = [
        {label: 'CASH', value: 'CASH'},
        {label: 'ONLINE', value: 'ONLINE'},
        {label: 'TARJETA DE CRÉDITO', value: 'TC'},
    ];
    public documentTypes: any[] = [];
    public documentTypesPER: any[] = [
        {label: 'DNI', value: 'DNI'},
        {label: 'RUC', value: 'RUC'},
        {label: 'PASAPORTE', value: 'PASAPORTE'},
        {label: 'CE', value: 'CE'},
    ];
    public documentTypesBRA: any[] = [
        {label: 'CPF', value: 'CPF'},
        {label: 'CNPJ', value: 'CNPJ'},
    ];
    public documentTypesCOL: any[] = [
        {label: 'CC', value: 'CC'},
        {label: 'CE', value: 'CE'},
        {label: 'NIT', value: 'NIT'},
    ];

    protected clearTimeout: any;

    constructor(
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private cookie: CookieService,
        private api: ApiService,
        private modal: NzModalService,
        private location: LocationStrategy,
        private utilitiesService: UtilitiesService,
    ) {
        this.createForm();
    }

    ngOnInit() {
        this.merchantActive = this.utilitiesService.getMerchantActive();
        this.getCurrencySupport();
        this.getCountrySupport();
        this.changeMerchant();
        this.setFormByMerchantType();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
        this.clearTimeout = clearTimeout(this.clearTimeout);
    }

    get isMerchantSalesIDValid() {
        return this.MerchantSalesID.invalid && this.MerchantSalesID.touched;
    }

    get isDetailValid() {
        return this.Detail.invalid && this.Detail.touched;
    }

    get isAmountValid() {
        return this.Amount.invalid && this.Amount.touched;
    }

    get isCurrencyCodeValid() {
        return this.CurrencyCode.invalid && this.CurrencyCode.touched;
    }

    get isTimeExpiredValid() {
        return this.TimeExpired.invalid && this.TimeExpired.touched;
    }

    get isChannelValid() {
        return this.Channel.invalid && this.Channel.touched;
    }

    get isFirstNameValid() {
        return this.newOrderForm.get('FirstName').invalid && this.newOrderForm.get('FirstName').touched;
    }

    get isLastNameValid() {
        return this.newOrderForm.get('LastName').invalid && this.newOrderForm.get('LastName').touched;
    }

    get isCountryCodeValid() {
        return this.newOrderForm.get('CountryCode').invalid && this.newOrderForm.get('CountryCode').touched;
    }

    get isDocTypeValid() {
        return this.newOrderForm.get('DocType').invalid && this.newOrderForm.get('DocType').touched;
    }

    get isDocNumberValid() {
        return this.newOrderForm.get('DocNumber').invalid && this.newOrderForm.get('DocNumber').touched;
    }

    get isEmailValid() {
        return this.newOrderForm.get('Email').invalid && this.newOrderForm.get('Email').touched;
    }

    get isMobileValid() {
        return this.newOrderForm.get('Mobile').invalid && this.newOrderForm.get('Mobile').touched;
    }

    get isCuotealoType(): boolean {

        if (this.merchantActive) {
            return this.merchantActive.MerchantData.TypeName !== MerchantType.LINKPAGO_CUOTEALO;
        }
        return false;
    }

    changeMerchant() {
        this.api.merchant$.pipe(takeUntil(this.destroy$)).subscribe(m => {
            if (m.event === 'change-merchant') {
                this.merchantActive = this.utilitiesService.getMerchantActive();                
                this.api.validateMerchantPermissions();
                this.setFormByMerchantType();
            }
        });
    }

    createForm() {
        this.newOrderForm = this.fb.group({
            OperationData: this.fb.group({
                MerchantSalesID: ['', Validators.required],
                Detail: [''],
                Amount: ['', [Validators.required, Validators.pattern(pattern)]],
                CurrencyCode: [null, Validators.required],
                TimeExpired: ['', [Validators.required, Validators.pattern(/^[0-9]*$/)]],
                Channel: ['', Validators.required],
            }),
            FirstName: ['', [Validators.required]],
            LastName: ['', [Validators.required]],
            CountryCode: ['', [Validators.required]],
            DocType: ['', [Validators.required]],
            DocNumber: ['', [Validators.required]],
            Email: ['', [Validators.required, Validators.email]],
            Mobile: ['', [Validators.required]]
        });
    }

    setFormByMerchantType() {
        if (this.merchantActive.MerchantData && this.merchantActive.MerchantData.TypeName === MerchantType.LINKPAGO_CUOTEALO) {
            this.Channel.setValue('ONLINE');
            this.Channel.disable();
            this.OperationData.removeControl('TimeExpired');

            this.Amount.setValidators([Validators.required, Validators.pattern(pattern), Validators.min(70)]);
            this.Amount.updateValueAndValidity();
        } else {
            this.CurrencyCode.enable();
            this.Channel.enable();
            this.OperationData.addControl('TimeExpired', this.fb.control('', [Validators.required, Validators.pattern(/^[0-9]*$/)]));

            this.Amount.setValidators([Validators.required, Validators.pattern(pattern)]);
            this.Amount.updateValueAndValidity();
        }
    }

    goBack() {
        this.location.back();
    }

    nextTab(): void {
        this.selectedIndexTab += 1;
    }

    backTab(): void {
        this.selectedIndexTab -= 1;
    }

    selectChange(value): void {
        this.selectedIndexTab = value.index;
    }

    submit() {
        if (this.newOrderForm.valid) {
            const data = {
                token: JSON.parse(this.cookie.get('ud')).token,
                merchant: this.merchant,
                service: 'cashin/transactions/post',
                type: 'post',
                data: {
                    MerchantCode: this.merchant,
                    MerchantSalesID: this.MerchantSalesID.value,
                    Detail: this.Detail.value,
                    Amount: this.Amount.value,
                    CurrencyCode: this.CurrencyCode.value,
                    TimeExpired: this.TimeExpired? this.TimeExpired.value : '1440',
                    OkURL: '',
                    ErrorURL: '',
                    Channel: this.Channel ? this.Channel.value : undefined,
                    FirstName: this.FirstName.value,
                    LastName: this.LastName.value,
                    CountryCode: this.CountryCode.value,
                    DocType: this.DocType.value,
                    DocNumber: this.DocNumber.value,
                    Email: this.Email.value,
                    Mobile: this.Mobile.value,
                    Metadata: JSON.stringify(JSON.parse(this.cookie.get('ud')).userDetails),

                }
            };
            
            this.api.api(data).toPromise().then((res: any) => {                
                $('#charging').addClass('hide');
                if (res.status === false) {
                    this.modal.error({nzTitle: 'Algo salió mal', nzContent: res.data.data.Message});
                } else {                    
                    this.shortUrl = res.ShortUrlRedirect ? res.ShortUrlRedirect : res.UrlRedirect;
                    this.modal.success({nzTitle: 'Orden creada', nzContent: this.modalContent, nzOnOk: () => this.goBack() });
                }
            }).catch(e => {
                $('#charging').addClass('hide');
                this.error(e.error);
            });

        } else {
            this.newOrderForm.markAllAsTouched();
        }
    }

    copyLink() {
        navigator.clipboard.writeText(this.shortUrl)
            .then(() => {
                this.iconCopy = 'check-circle';
                this.clearTimeout = setTimeout(() => {
                    this.iconCopy = 'copy';
                }, 3000);

            /* Resuelto - texto copiado al portapapeles con éxito */
            },() => {
            console.error('Error al copiar');
            /* Rechazado - fallo al copiar el texto al portapapeles */
        });
    }

    success(title, message?: string): void {
        this.modal.success({nzTitle: title, nzContent: message});
        this.clearTimeout = setTimeout(() => {
            this.router.navigate(['cashin-payments-orders']);
        }, 500);
    }

    error(errors: Array<string>): void {
        let messageError = '';
        errors.forEach(msg => messageError += msg + '<br>');
        this.modal.error({nzTitle: 'Algo salió mal', nzContent: messageError});
    }

    getCurrencySupport() {
        const merchants = JSON.parse(this.cookie.get('ud')).MerchantActive;
        const merchantActive = merchants.find(merchant => merchant.MerchantData.MerchantCode === this.merchant);
        this.supportedCurrencies = merchantActive.CurrencySupported.Cashin;
    }

    getCountrySupport() {
        const merchants = JSON.parse(this.cookie.get('ud')).MerchantActive;
        const merchantActive = merchants.find(merchant => merchant.MerchantData.MerchantCode === this.merchant);
        const countryCode = merchantActive.CountrySupport[0].CountryCode;
        this.supportedCountries = merchantActive.CountrySupport;

        switch (countryCode) {
            case 'BRA':
                this.documentTypes = this.documentTypesBRA;
                break;
            case 'PER':
                this.documentTypes = this.documentTypesPER;
                break;
            case 'COL':
                this.documentTypes = this.documentTypesCOL;
                break;
            default:
                this.documentTypes = this.documentTypesPER;
        }
    }

    onCountryChange(countryCode: string) {
        switch (countryCode) {
            case 'BRA':
                this.documentTypes = this.documentTypesBRA;
                break;
            case 'PER':
                this.documentTypes = this.documentTypesPER;
                break;
            case 'COL':
                this.documentTypes = this.documentTypesCOL;
                break;
            default:
                this.documentTypes = this.documentTypesPER;
        }
        this.DocType.setValue(null);
    }

    get OperationData() {
        return this.newOrderForm.get('OperationData') as FormGroup;
    }

    get MerchantSalesID() {
        return this.OperationData.get('MerchantSalesID');
    }

    get Detail() {
        return this.OperationData.get('Detail');
    }

    get Amount() {
        return this.OperationData.get('Amount');
    }

    get CurrencyCode() {
        return this.OperationData.get('CurrencyCode');
    }

    get TimeExpired() {
        return this.OperationData.get('TimeExpired');
    }

    get Channel() {
        return this.OperationData.get('Channel');
    }

    get FirstName() {
        return this.newOrderForm.get('FirstName');
    }

    get LastName() {
        return this.newOrderForm.get('LastName');
    }

    get CountryCode() {
        return this.newOrderForm.get('CountryCode');
    }

    get DocType() {
        return this.newOrderForm.get('DocType');
    }

    get DocNumber() {
        return this.newOrderForm.get('DocNumber');
    }

    get Email() {
        return this.newOrderForm.get('Email');
    }

    get Mobile() {
        return this.newOrderForm.get('Mobile');
    }


}
